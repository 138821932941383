import { Box } from '../../components/Box'
import { Text } from '../../components/Text'
import { useParams } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { useCategory } from '../../hooks/useCategory'
import { useData } from '../../hooks/useData'
import { useMe } from '../../hooks/useMe'
import { observer } from 'mobx-react'

export const SignedOutBy = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)
  const { user: signedOutByUser } = useUser(data?.getSignedOutBy() || undefined)

  if (!category || !me || !data) {
    return <></>;
  }

  if (!category.getSignInOut()) {
    return <></>;
  }

  if (data.canSignOut()) {
    return <></>;
  }

  return (
    <Box direction='vertical' alignVertical='center' paddingTopCode='lg' paddingBottomCode='xs'>
      <Box paddingBottomCode='xxxs'>
        <Text size='xs' color='fadedWhite'>Signed out by</Text>
      </Box>
      <Text size='md' color='white' weight='extra'>{signedOutByUser?.name}</Text>
    </Box>
  )
})
