import { useQuery } from 'react-query';
import { versionRepo } from '../repositories/VersionRepo';
import { Version } from '../models/Version';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useVersion = (categoryId?: string, recordId?: string, versionId?: string) => {
  const { 
    isLoading, 
    data: version,
    refetch,  
  } = useQuery(['version', versionId, recordId, categoryId], async () => {
    if (!versionId || !recordId || !categoryId) return null

    const versionData = await versionRepo.getById(categoryId, recordId, versionId)
    const version = new Version();
    version.fromRawData(versionData) 

    return version
  }, options)

  return {
    version,
    refetch,
    isLoading,
  }
}