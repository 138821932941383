import axios, { AxiosError } from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface Tenant { 
  stripeCustomerId: string
}

class TenantRepo {
  
  public async getTenant() {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/tenants/handledByMiddleware`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true})
      const resource = response.data.resource as Tenant
      return resource
    } catch(error) {
      if (axios.isAxiosError(error)) {
        if (error.status === 404) {
          return null
        }
      }
      throw error
    }
  }
}

export const tenantRepo = new TenantRepo()
