import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { DataRow } from '../components/DataRow'
import { DataCol } from '../components/DataCol'
import { Link } from "../components/Link";
import { useUsers } from '../hooks/useUsers'
import { uuid } from '../helpers/UUID'
import { observer } from 'mobx-react'
import { useUsersTypes } from '../hooks/useUsersTypes'

export const UsersTypes = observer(function () {
  const { userTypes } = useUsersTypes()

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
          {userTypes?.map(userType => (
            <Link to={`${userType.getId()}`} key={`edit-user-type-${userType.getId()}`}>
              <DataRow>
                <DataCol>{userType.getName()}</DataCol>
              </DataRow>
            </Link>
          ))}

        </ListContainer>
        <SideBar>
          
          <Button to={`${uuid.generate()}`}>New Type</Button>

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})