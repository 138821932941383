import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export function Page(props: Props) {
  return (
    <Content onClick={(event) => event.stopPropagation()}>
      {props.children}
    </Content>
  )
}