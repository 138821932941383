import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import { Header } from "./Header";
import { Footer } from "./Footer";

const MainSection = styled.div`
  font-size: ${theme.typography.textSizes.xl};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
  flex-direction: column;
  align-items: center
`

export const Terms = observer(function () {
  return <>

    <Header />

    <MainSection>

      <Box paddingTopCode="xxxl">
        <Box paddingBottomCode="xl">
          <Text size="xxxl" weight="medium" color="black">Terms of Service for Record Rooster</Text>
        </Box>
        <Text size="md">
          1. Introduction<br/>
          <br/>
          Welcome to our Record Rooster ("App"). These Terms of Service ("Terms") govern your use of our App. By using the App, you agree to comply with these Terms.<br/>
          <br/>
          2. User Responsibilities<br/>
          <br/>
          You are responsible for maintaining the confidentiality of your account and password.<br/>
          You agree to provide accurate and complete information when using the App.<br/>
          You are responsible for all activities that occur under your account.<br/>
          <br/>
          3. App Usage<br/>
          <br/>
          The App is intended for personal record-keeping purposes only.<br/>
          You agree not to use the App for any illegal or unauthorized purpose.<br/>
          <br/>
          4. Data Integrity<br/>
          <br/>
          While we strive to maintain the accuracy of data, we do not guarantee the correctness or completeness of the data stored in the App.<br/>
          You are advised to verify the accuracy of the data entered and maintained in the App.<br/>
          <br/>
          5. Data Loss and Security<br/>
          <br/>
          We are not responsible for any loss, corruption, or theft of data.<br/>
          The security of your data is your responsibility, and we recommend regular backups.<br/>
          We are not liable for any data loss due to unauthorized access to your account.<br/>
          <br/>
          6. Limitation of Liability<br/>
          <br/>
          The App is provided "as is" and "as available" without any warranties.<br/>
          We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the App.<br/>
          <br/>
          7. Changes to Terms<br/>
          <br/>
          We reserve the right to modify these Terms at any time.<br/>
          Your continued use of the App following any changes indicates your acceptance of the new Terms.<br/>
          <br/>
          8. Contact<br/>
          <br/>
          For any questions or concerns regarding these Terms, please contact us.<br/>
          <br/>
          By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </Text>
      </Box>

    </MainSection>

    <Footer />

  </>
})
