import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { useData } from '../../hooks/useData'
import { observer } from "mobx-react";

export const ArchiveButton = observer(function () {
  const { recordId, categoryId } = useParams()
  const { data } = useData(categoryId, recordId)

  if (!data) {
    return <></>
  }

  if (data.isNew()) {
    return <></>
  }

  return (
    <Button onClick={() => { data.getIsArchived() ? data.unarchive() : data.archive() }}>
      {data.getIsArchived() ? 'Unarchive' : 'Archive'}
    </Button> 
  )
})
