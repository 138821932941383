export enum Permissions {
  SETTINGS = 'settings',
  CATEGORIES_READ = 'categories.{category_id}.read',
  REQUESTED_CHANGES_READ = 'requested-changes.read',
  VERSIONS_READ = 'versions.read',
  CATEGORY_REVIEW = 'categories.{category_id}.review',
  CATEGORY_SAVE_WITHOUT_APPROVAL = 'categories.{category_id}.save-without-approval',
}

export function injectCategoryId(permission: Permissions, categoryId: string){
  return permission.replace('{category_id}', categoryId)
}