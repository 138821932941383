import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface RawVersion { 
  id: string
  date: string
  userId: string
  recordId: string
  approverId?: string | null
  data: {
    fieldId: string
    value: string
  }[]
}

class VersionRepo {

  public  async getById(categoryId: string, recordId: string, id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records/${recordId}/versions/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawVersion
      return resource
    } catch(e) {
      console.log('Failed to get version by id',e)
    }
    return null
  }

  public async getByRecordId(categoryId: string, recordId: string): Promise<RawVersion[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records/${recordId}/versions`, { headers: { Authorization: `Bearer ${token}` }})
      const resources = response.data.resources as RawVersion[]
      return resources
    } catch(e) {
      console.log('Failed to get versions',e)
    }
    return []
  }

  public  async save(categoryId: string, data: RawVersion) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/categories/${categoryId}/records/${data.recordId}/versions/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawVersion
      return resource
    } catch(e) {
      console.log('Failed to save version',e)
    }
    return null
  }

}

export const versionRepo = new VersionRepo()
