import styled from "styled-components";
import { theme } from "../theme";
import { Search } from '@mui/icons-material';

interface Props {
  label?: string;
  onChange?: (checked: string) => void;
  value?: string;
  autoFocus?: boolean;
}

const Container = styled.div<{ disabled?: boolean; }>`
  background-color: rgba(255,255,255,0.04);
  color: white;
  display: flex;
  justify-content: left;
  margin: ${theme.spacing.xxs};
  border-radius: 12px;
  transition: all 0.2s;
  user-select: none;
  align-items: center;
  padding-left: ${theme.spacing.sm};
`

const SearchBox = styled.input`
  padding-top: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.sm};
  background-color: transparent;
  color: white;
  font-size: ${theme.typography.textSizes.md};
  font-weight: ${theme.typography.weights.medium};
  outline: none;
  border: none;
`

export function SidebarSearchbox(props: Props) {
  return (
    <Container>
      <Search />
      <SearchBox autoFocus={props.autoFocus} value={props.value} onChange={(event) => props.onChange && props.onChange(event.target.value)} placeholder={props.label} />
    </Container>
  )
}
