import { Background } from '../components/Background'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import styled from 'styled-components'
import { UserPanel } from './Users'
import { Home } from './Home'
import { EditUserPage } from './EditUser'
import { EditDocument } from './EditDocument/EditDocument'
import { CategoriesPane } from './Categories';
import { EditCategoryPage } from './EditCategory';
import { EditFieldsPage } from './Fields';
import { EditFieldPage } from './EditFieldPage';
import { UsersTypes } from './UsersTypes';
import { EditUserTypePage } from './EditUserType';
import { Promo } from './Promo/Promo';
import { SignIn } from './SignIn';
import { LoadingScreen } from '../components/LoadingScreen';
import { StoragePane } from './Storage';
import { Terms } from './Promo/Terms';
import { Privacy } from './Promo/Privacy';

export function SiteRouter() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/:categoryId/record/:recordId" element={
            <>
              <Home />
              <EditDocument />
            </>
          } />
          <Route path="/:categoryId/record/:recordId/versions/:versionId" element={
            <>
              <Home />
              <EditDocument />
            </>
          } />
          <Route path="/:categoryId/record/:recordId/requested-changes/:requestedChangeId" element={
            <>
              <Home />
              <EditDocument />
            </>
          } />
          <Route path="/:categoryId/users" element={
            <>
              <Home />
              <UserPanel />
            </>
          } />
          <Route path="/:categoryId/storage" element={
            <>
              <Home />
              <StoragePane />
            </>
          } />
          <Route path="/:categoryId/categories" element={
            <>
              <Home />
              <CategoriesPane />
            </>
          } />
          <Route path="/:categoryId/categories/:editCategoryId" element={
            <>
              <Home />
              <CategoriesPane />
              <EditCategoryPage />
            </>
          } />
          <Route path="/:categoryId/categories/:editCategoryId/fields" element={
            <>
              <Home />
              <CategoriesPane />
              <EditCategoryPage />
              <EditFieldsPage />
            </>
          } />
          <Route path="/:categoryId/categories/:editCategoryId/fields/:fieldId" element={
            <>
              <Home />
              <CategoriesPane />
              <EditCategoryPage />
              <EditFieldsPage />
              <EditFieldPage />
            </>
          } />
          <Route path="/:categoryId/users/user-types" element={
            <>
              <Home />
              <UserPanel />
              <UsersTypes />
            </>
          } />
          <Route path="/:categoryId/users/user-types/:userTypeId" element={
            <>
              <Home />
              <UserPanel />
              <UsersTypes />
              <EditUserTypePage />
            </>
          } />
          <Route path="/:categoryId/users/:userId" element={
            <>
              <Home />
              <UserPanel />
              <EditUserPage />
            </>
          } />
          <Route path="/:categoryId" element={<Home />} />
          <Route path="/" element={<Promo />} />
        </Routes>
      </Router>
    </>
  )
}