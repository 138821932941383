import { Button } from '../../components/Button'
import { useParams } from 'react-router-dom'
import { useCategory } from '../../hooks/useCategory'
import { useMe } from '../../hooks/useMe'
import { useData } from '../../hooks/useData'
import { observer } from 'mobx-react'
import { useVersions } from '../../hooks/useVersions'

export const VersionsButton = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)
  const { versions } = useVersions(categoryId, recordId)

  if (!category || !me || !data) {
    return <></>;
  }

  if (!versions?.length) {
    return <></>;
  }

  const mostRecentVersion = versions[versions.length - 1]

  return (
    <Button to={`versions/${mostRecentVersion.getId()}`}>
      History
    </Button>
  )
})
