import { useQuery } from 'react-query';
import { Record } from '../models/Record';
import { recordRepo } from '../repositories/RecordRepo';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useAllData = (categoryId?: string) => {
  const { 
    isLoading, 
    data,
    refetch,  
  } = useQuery(['data', categoryId], async () => {
    if (!categoryId) return null

    const recordData = await recordRepo.getByCategoryId(categoryId)
    const records = recordData.map(row => {
      const record = new Record();
      record.fromRawData(row) 
      return record
    })

    return records
  }, options)

  return {
    data,
    refetch,
    isLoading,
  }
}