import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';

interface BoxViewProps {
  paddingCode?: keyof typeof theme.spacing;
  paddingTopCode?: keyof typeof theme.spacing;
  paddingBottomCode?: keyof typeof theme.spacing;
  paddingLeftCode?: keyof typeof theme.spacing;
  paddingRightCode?: keyof typeof theme.spacing;

  marginCode?: keyof typeof theme.spacing;
  marginTopCode?: keyof typeof theme.spacing;
  marginBottomCode?: keyof typeof theme.spacing;
  marginLeftCode?: keyof typeof theme.spacing;
  marginRightCode?: keyof typeof theme.spacing;

  alignVertical?: 'top' | 'center' | 'bottom' | 'space-between';
  alignHorizontal?: 'left' | 'center' | 'right' | 'space-between';
  direction?: 'horizontal' | 'vertical';
}

interface Props extends BoxViewProps {
  children: React.ReactNode;
}

const BoxView = styled.div<BoxViewProps>`
  display: flex;

  ${(props) => props.paddingCode ? `padding: ${theme.spacing[props.paddingCode]};` : ''}
  ${(props) => props.paddingTopCode ? `padding-top: ${theme.spacing[props.paddingTopCode]};` : ''}
  ${(props) => props.paddingBottomCode ? `padding-bottom: ${theme.spacing[props.paddingBottomCode]};` : ''}
  ${(props) => props.paddingLeftCode ? `padding-left: ${theme.spacing[props.paddingLeftCode]};` : ''}
  ${(props) => props.paddingRightCode ? `padding-right: ${theme.spacing[props.paddingRightCode]};` : ''}

  ${(props) => props.marginCode ? `margin: ${theme.spacing[props.marginCode]};` : ''}
  ${(props) => props.marginTopCode ? `margin-top: ${theme.spacing[props.marginTopCode]};` : ''}
  ${(props) => props.marginBottomCode ? `margin-bottom: ${theme.spacing[props.marginBottomCode]};` : ''}
  ${(props) => props.marginLeftCode ? `margin-left: ${theme.spacing[props.marginLeftCode]};` : ''}
  ${(props) => props.marginRightCode ? `margin-right: ${theme.spacing[props.marginRightCode]};` : ''}

  ${(props) => props.alignVertical ? `align-items: ${props.alignVertical};` : ''}
  ${(props) => props.alignHorizontal ? `justify-content: ${props.alignHorizontal};` : ''}
  ${(props) => props.direction ? `flex-direction: ${props.direction === 'horizontal' ? 'row' : 'column'};` : ''}
`;

export function Box(props: Props) {
  return (
    <BoxView 
      paddingCode={props.paddingCode}
      paddingTopCode={props.paddingTopCode}
      paddingBottomCode={props.paddingBottomCode}
      paddingLeftCode={props.paddingLeftCode}
      paddingRightCode={props.paddingRightCode}
      marginCode={props.marginCode}
      marginTopCode={props.marginTopCode}
      marginBottomCode={props.marginBottomCode}
      marginLeftCode={props.marginLeftCode}
      marginRightCode={props.marginRightCode}
      alignHorizontal={props.alignHorizontal}
      alignVertical={props.alignVertical}
      direction={props.direction}
    >
      {props.children}
    </BoxView>
  )
}
