import styled from "styled-components";
import { theme } from "../theme";

const defaultWidth = 200;

interface Props {
  children: React.ReactNode;
  width?: number;
  onClick?: () => void;
  align?: 'left' | 'center' | 'right';
}

const Container = styled.div<{ width?: number; align?: Props['align']; }>`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  text-align: ${props => props.align || 'left'};
  justify-content: ${props => props.align || 'left'};
  padding-left: ${theme.spacing.xxs};
  padding-top: ${theme.spacing.xxs};
  padding-bottom: ${theme.spacing.xxs};
  width: ${props => props.width || defaultWidth}px;
  overflow: hidden;
  white-space: no-wrap;
  user-select: none;
  height: 100%;
  align-items: center;
`

export function DataCol(props: Props) {
  return (
    <Container align={props.align} onClick={props.onClick} width={props.width}>
      {props.children}
    </Container>
  )
}