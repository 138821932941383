import { useQuery } from 'react-query';
import { User } from '../models/User';
import { userRepo } from '../repositories/UserRepo';
import { UserType } from '../models/UserType';
import { userTypeRepo } from '../repositories/UserTypeRepo';
import { useUsersTypes } from './useUsersTypes';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useUsersType = (id: string) => {
  const {userTypes: allUserTypes} = useUsersTypes()

  const { 
    isLoading, 
    data: userType,
    refetch,  
  } = useQuery(['user-types', id, allUserTypes], async () => {
    if (!id) return

    const type = allUserTypes?.find(type => type.getId() === id);

    return type
  }, options)

  return {
    userType,
    refetch,
    isLoading,
  }
}