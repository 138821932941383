import { useQuery } from 'react-query';
import { User } from '../models/User';
import { userRepo } from '../repositories/UserRepo';
import { UserType } from '../models/UserType';
import { userTypeRepo } from '../repositories/UserTypeRepo';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useUsersTypes = () => {
  const { 
    isLoading, 
    data: userTypes,
    refetch,  
  } = useQuery(['user-types'], async () => {

    const userData = await userTypeRepo.getAll()
    const users = userData.map(row => {
      const userType = new UserType();
      userType.fromRawData(row) 
      return userType
    })

    const formattedUserTypes = users.map(userType => {
      if (userType.getDeleted()) {
        userType.setName(`${userType.getName()} (deleted)`)
      }
      return userType
    })

    return formattedUserTypes
  }, options)

  return {
    userTypes,
    refetch,
    isLoading,
  }
}