const defaultTranslations = {
  'Inventory Adjustments': 'Inventory Adjustments',
  'Adjustment History': 'Adjustment History',
  'Inventory Adjustment': 'Inventory Adjustment',
  'Add Adjustment': 'Add Adjustment',
  'Back': 'Back',
  'Debate': 'Debate',
}

export type Message = keyof typeof defaultTranslations;
export type TranslationSet = { [key in Message]: string };

export const en: TranslationSet = defaultTranslations;
