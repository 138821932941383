import styled from "styled-components";
import { theme } from "../theme";
import { Add } from '@mui/icons-material';
import { Link } from "./Link";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  to?: string;
}

const Container = styled.div<{ disabled?: boolean; }>`
  background-color: rgba(255,255,255,0.05);
  font-size: ${theme.typography.textSizes.sm};
  color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  margin: ${theme.spacing.xxs};
  border-radius: 12px;
  transition: all 0.2s;
  line-height: 150%;
  font-weight: ${theme.typography.weights.strong};
  user-select: none;
  &:hover {
    color: white;
    background-color: rgba(255,255,255,0.09);
  }
  &:active {
    filter: brightness(50%);
  }
`

export function Button(props: Props) {
  return (
    <Link to={props.to || undefined} onClick={props.onClick || undefined}>
      <Container disabled={props.disabled}>
        {props.children}
      </Container>
    </Link>
  )
}