import { useQuery } from 'react-query';
import { Category } from '../models/Category';
import { useCategories } from './useCategories';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useCategory = (id?: string) => {
  const { categories, isLoading: isLoadingCategories } = useCategories()

  const { 
    isLoading, 
    data: category,
    refetch,  
  } = useQuery(['category', id, isLoadingCategories, ], async () => {
    if (!id) return null 
    if (isLoadingCategories) return null

    // Attempt to use already loaded version first 
    const categoryInCategories = categories?.find(category => category.getId() === id)
    if (categoryInCategories) {
      return categoryInCategories
    }

    const category = new Category()
    await category.load(id)
    
    return category
  }, options)

  return {
    category,
    refetch,
    isLoading,
  }
}