import { Button } from '../../components/Button'
import { useParams } from 'react-router-dom'
import { useCategory } from '../../hooks/useCategory'
import { useData } from '../../hooks/useData'
import { useMe } from '../../hooks/useMe'
import { observer } from 'mobx-react'

export const SignInButton = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)

  if (!category || !me || !data) {
    return <></>;
  }

  if (!category.getSignInOut()) {
    return <></>;
  }

  if (!me) {
    return <></>;
  }

  if (!data.canSignIn(me.getId())) {
    return <></>;
  }

  return (
    <Button onClick={() => data.signIn()}>Sign In</Button>
  )
})
