import { useQuery } from 'react-query';
import { RecordSearch } from '../models/RecordSearch';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useRecordSearch = () => {
  const { 
    isLoading, 
    data: recordSearch,
    refetch,  
  } = useQuery(['recordSearch'], async () => {
    const recordSearch = new RecordSearch()
    return recordSearch
  }, options)

  return {
    recordSearch,
    refetch,
    isLoading,
  }
}