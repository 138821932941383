import styled from "styled-components";
import { theme } from "../theme";

interface Props {
  title: string;
}

const Container = styled.div`
  font-size: ${theme.typography.textSizes.sm};
  padding: ${theme.spacing.sm};
  padding-bottom: ${theme.spacing.xxs};
  flex-direction: center;
  text-align: center;
  align-items: center;
  color: rgba(255,255,255,0.5);
`

export function ListTitle(props: Props) {
  return (
    <Container>
      {props.title}
    </Container>
  )
}