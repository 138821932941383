import { TranslationSet } from './en';

export const fr: TranslationSet = {
  'Inventory Adjustments': 'Inventory Adjustments',
  'Adjustment History': 'Adjustment History',
  'Inventory Adjustment': 'Inventory Adjustment',
  'Add Adjustment': 'Add Adjustment',
  'Back': 'Back',
  'Debate': 'Debate',
}
