import { makeAutoObservable, toJS } from "mobx"
import { RawRecord, recordRepo } from "../repositories/RecordRepo";
import { Field } from "./Field";
import moment from "moment";

export class Record {
  private id: string = ''
  private categoryId: string = ''
  private signedOutBy: string | null = null;
  private data: { [key: string]: string; } = {};
  private edits: any = {};
  private hasChangeRequests: boolean = false;
  private pendingNewDocumentApproval = false;
  private isArchived = false;
  private deleted = false;

  public getDeleted(): boolean {
    return this.deleted
  }

  public setDeleted(deleted: boolean): void {
    this.deleted = deleted
  }

  constructor() {
    makeAutoObservable(this);
  }

  public isNew(): boolean {
    const hasNoData = Object.keys(this.data).length === 0
    return hasNoData
  }

  public getPendingNewDocumentApproval(): boolean {
    return this.pendingNewDocumentApproval
  }

  public setPendingNewDocumentApproval(pendingNewDocumentApproval: boolean): void {
    this.pendingNewDocumentApproval = pendingNewDocumentApproval
  }

  public signOut(userId: string): void {
    this.signedOutBy = userId;
    this.save()
  }

  public canSignOut(): boolean {
    return !Boolean(this.signedOutBy);
  } 

  public canSignIn(userId: string): boolean {
    return userId === this.signedOutBy;
  } 

  public getFieldValue(fieldId: string): string {
    return this?.data[fieldId] || ''
  }

  public getEditValue(fieldId: string): string|null {
    return this.edits[fieldId]
  }

  public hasEditValue(fieldId: string): boolean {
    return this.edits[fieldId] !== undefined && (this.edits[fieldId] || '') !== (this.data[fieldId] || '')
  }

  public setEditValue(fieldId: string, value: string): void {
    this.edits[fieldId] = value
  }

  public clearEdits(): void {
    this.edits = {}
  }

  public getEdits(): any {
    return this.edits
  }

  public hasEdits(): boolean {
    const editedFieldIds = Object.keys(this.edits)
    const hasEdits = editedFieldIds.find((fieldId) => this.hasEditValue(fieldId))
    return Boolean(hasEdits)
  }

  public setData(data: any): void {
    this.data = data
  }

  public mergeData(data: any): void {
    this.data = Object.assign({}, this.data, data)
  }

  public getData(): object {
    return this.data
  }

  public signIn(): void {
    this.signedOutBy = null
    this.save()
  }

  public getSignedOutBy(): string | null {
    return this.signedOutBy
  }

  public setSignedOutBy(userId: string | null): void {
    this.signedOutBy = userId
  }

  public getCategoryId(): string {
    return this.categoryId
  }

  public setCategoryId(categoryId: string): void {
    this.categoryId = categoryId
  }

  public getId(): string {
    return this.id
  }

  public setId(Id: string): void {
    this.id = Id
  }

  private setIsArchived(isArchived: boolean): void {
    this.isArchived = isArchived
  }

  public getIsArchived(): boolean {
    return this.isArchived
  }

  public archive(): void {
    this.isArchived = true
    this.save()
  }

  public unarchive(): void {
    this.isArchived = false
    this.save()
  }
  
  public getHasChangeRequests(): boolean {
    return this.hasChangeRequests
  }

  public setHasChangeRequests(hasChangeRequests: boolean): void {
    this.hasChangeRequests = hasChangeRequests
  }

  public async saveEdits() {
    const newData = Object.assign({}, this.data, this.edits)
    this.data = newData
    await this.save()
    this.clearEdits()
  }

  private reset() {
    this.fromRawData(null)
  }

  public async load(categoryId: string, id: string) {
    this.reset()
    this.setId(id)
    const data = await recordRepo.getById(categoryId, id)
    if (data) {
      this.fromRawData(data)
    }
  }

  public setDateCreated(fields: Field[]) {
    const now = moment().toISOString()

    const dateCreatedField = fields.find(field => field.getReservedType() === 'dateCreated')
    if (dateCreatedField?.getId() && !this.getFieldValue(dateCreatedField.getId())) {
      this.setEditValue(dateCreatedField.getId(), now)
    }
  }

  public setDateChanged(fields: Field[]) {
    const now = moment().toISOString()

    const dateChangedField = fields.find(field => field.getReservedType() === 'dateChanged')
    if (dateChangedField?.getId()) {
      this.setEditValue(dateChangedField.getId(), now)
    }
  }

  public fromRawData(rawRecord: RawRecord | null) {
    const fieldData: { [key: string]: string } = {}
    for (const row of rawRecord?.data || []) {
      fieldData[row.fieldId] = row.value
    }
    this.setId(rawRecord?.id || '')
    this.setCategoryId(rawRecord?.categoryId || '')
    this.setSignedOutBy(rawRecord?.signedOutBy || null)
    this.setData(fieldData)
    this.setDeleted(rawRecord?.deleted || false)
    this.setHasChangeRequests(rawRecord?.hasChangeRequests || false)
    this.setIsArchived(typeof rawRecord?.isArchived === 'undefined' ? false : rawRecord.isArchived)
    this.setPendingNewDocumentApproval(typeof rawRecord?.pendingNewDocumentApproval === 'undefined' ? false : rawRecord.pendingNewDocumentApproval)
  }

  public canSave(fields: Field[]) { 
    const requiredFields = fields.filter(field => field.getRequired())
    for (const requiredField of requiredFields){

      const fieldValue = this.getFieldValue(requiredField.getId())
      const editValue = this.getEditValue(requiredField.getId())
      const effectiveValue = editValue !== undefined ? editValue : fieldValue

      const trimmedValue = (effectiveValue || '').trim()
      if (!trimmedValue) {
        return false
      }
    }
    return true
  }

  public async save(): Promise<void> {
    const data = Object.entries(this.getData()).map(([key,value]) => ({
      fieldId: key,
      value
    }))
    await recordRepo.save(this.getCategoryId(), {
      id: this.getId(),
      categoryId: this.getCategoryId(),
      signedOutBy: this.getSignedOutBy(),
      hasChangeRequests: this.getHasChangeRequests(),
      pendingNewDocumentApproval: this.getPendingNewDocumentApproval(),
      isArchived: this.getIsArchived(),
      data,
      deleted: this.getDeleted(),
    })
  }
}