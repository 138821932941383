import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Page } from '../components/Page'
import { Button } from '../components/Button'
import { DataRow } from '../components/DataRow'
import { DataCol } from '../components/DataCol'
import { Box } from '../components/Box'
import { Link } from "../components/Link";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCategories } from '../hooks/useCategories'
import { useEffect, useState } from 'react'
import { useMe } from '../hooks/useMe'
import { observer } from "mobx-react";
import { useFields } from '../hooks/useFields'
import { uuid } from '../helpers/UUID' 
import { SidebarCheckbox } from '../components/SidebarCheckbox'
import { useRecordSearch } from '../hooks/useRecordSearch'
import { SidebarSearchbox } from '../components/SidebarSearchbox'
import { LogoutButton } from '../components/LogoutButton'
import { SpaceBetween } from '../components/SpaceBetween'
import { SortArrow } from '../components/SortArrow'
import { SidebarDropdown } from '../components/SidebarDropdown'
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { useCategory } from '../hooks/useCategory'
import { EmptyState } from '../components/EmptyState'
import moment from 'moment'
import { useUsersType } from '../hooks/useUsersType'
import { Background } from '../components/Background'
import { LoadingScreen } from '../components/LoadingScreen'
import { Spinner } from '../components/Spinner'
import { Permissions, injectCategoryId } from '../shared/Permissions'
import { subscriptionRepo } from '../repositories/SubscriptionRepo'
import { tenantRepo } from '../repositories/TenantRepo'

export const Home = observer(function () {
  const { categoryId } = useParams()
  const { me } = useMe()
  const { categories } = useCategories()
  const { category } = useCategory(categoryId)
  const { fields, isLoading } = useFields(categoryId)
  const navigate = useNavigate();
  const { recordSearch } = useRecordSearch()
  const data = recordSearch?.getResults()
  const listFields = fields?.filter(field => field.getShowInList())
  const sortedFields = listFields?.sort((a,b) => { return (a.getSortOrder() || 0) > (b.getSortOrder() || 0) ? 1 : -1 })
  const { userType } = useUsersType(me?.getType() || '')
  const [searchParams] = useSearchParams()
  const stripeSessionId = searchParams.get('session_id')

  async function checkIfSubscribed() {
    if (stripeSessionId) {
      await subscriptionRepo.updateSubscription(stripeSessionId)
    }

    try {
      const tenant = await tenantRepo.getTenant()
      if (!tenant?.stripeCustomerId) {
        const stripeUrl = await subscriptionRepo.getNewSubscription();
        window.location = stripeUrl
      }
    } catch(error) {
      alert('Failed to get tenant')
    }
    
  }

  useEffect(() => {
    checkIfSubscribed()
  }, [])

  // Set category if none selected
  useEffect(() => {
    if (!categories?.length) {
      return;
    }
    if (!categoryId) {
      const selectedDefault = categories?.find(category => category?.getDefault())
      const fallback = categories[0] || null
      const defaultCategory = selectedDefault || fallback

      if (defaultCategory) {
        navigate(`/${defaultCategory.getId()}?session_id=${encodeURIComponent(stripeSessionId || '')}`, { replace: true, })
      }
    }
  }, [categoryId, categories, navigate])

  // Search
  useEffect(() => {
    if (categoryId && recordSearch) {
      recordSearch.setCategoryId(categoryId)
    }
  }, [categoryId, recordSearch])

  return (
    <>
      <Background> </Background>
      <StackedChildren dontAnimateIn>
        <Page>
          <SideBar>
            <SpaceBetween direction="vertical">

              <div>
                <Box alignHorizontal='center' paddingTopCode='lg' paddingBottomCode='md'>
                  <img src={require('../assets/whitelogo.png')} width={40} alt='logo' style={{ opacity: '0.5' }} />
                </Box>

                <SidebarDropdown 
                  options={
                    categories?.map(category => 
                      ({ label: category.getName(), value: category.getId() })
                    ) || []
                  }
                  value={categoryId || ''}
                  onChange={(newValue) => {
                    navigate(`/${newValue}`)
                  }}
                />

                <SidebarSearchbox autoFocus value={recordSearch?.getSearchText()} onChange={(newText) => recordSearch?.setSearchText(newText)} />

                <SidebarCheckbox 
                  label='Bookmarks' 
                  value={recordSearch?.getShowBookmarks()} 
                  onClick={(newValue) => {
                    recordSearch?.setShowBookmarks(newValue)
                  }} 
                />

                <SidebarCheckbox 
                  label='Show Archived' 
                  value={recordSearch?.getShowArchived()} 
                  onClick={(newValue) => {
                    recordSearch?.setShowArchived(newValue)
                  }} 
                />
                
                {userType?.hasPermission(injectCategoryId(Permissions.CATEGORY_REVIEW, category?.getId() ?? '')) && category?.getRequireApproval() && (
                  <SidebarCheckbox 
                    label='Change Requests' 
                    value={recordSearch?.getShowRequestedChanges()}
                    onClick={(newValue) => {
                      recordSearch?.setShowRequestedChanges(newValue)
                    }}
                  />
                )}
              
                <Button to={`record/${uuid.generate()}`}>New Document</Button>

                {userType?.hasPermission(Permissions.SETTINGS) && (
                  <Button to={`users`}>Users</Button>
                )}

                {userType?.hasPermission(Permissions.SETTINGS) && (
                  <Button to={`categories`}>Categories</Button>
                )}

                {userType?.hasPermission(Permissions.SETTINGS) && (
                  <Button to={`storage`}>File Storage</Button>
                )}
              </div>

              <LogoutButton />

            </SpaceBetween>
          </SideBar>
          <ListContainer>

            <div style={{ position: 'sticky', top: 0, zIndex: 99 }}>
              <DataRow titleRow>
                {sortedFields?.map(field => (
                  <span style={{ cursor: 'pointer' }} key={'list-field-home-'+field.getId()} onClick={() => recordSearch?.setSortField(field.getId())}>
                    <DataCol align={field.getAlignment()} key={'title-'+field.getId()} width={field.getWidth()}>
                      {field.getName()}
                      {recordSearch?.getSortField() === field.getId() && (
                        <SortArrow direction={recordSearch.getSortDirection()} />
                      )}
                    </DataCol>
                  </span>
                ))}
              </DataRow>
            </div>

            {recordSearch?.hasLoaded() && (
              <EmptyState show={!Boolean(data?.length)} />
            )}

            {!recordSearch?.hasLoaded() && (
              <Spinner />
            )}

            {data?.map(record => {
              return (
                <DataRow key={'row-'+record.getId()} >
                  {sortedFields?.map(field => (
                    <Link 
                      key={'row-item-'+record.getId()+'-'+field.getId()} 
                      onClick={() => {
                        navigate(`record/${record.getId()}`)
                        setTimeout(() => {
                          document.getElementById(field.getId())?.focus()
                        }, 250);
                      }}
                    >
                      <DataCol align={field.getAlignment()} width={field.getWidth()}>
                        {field.getType() === 'checkbox' && <>
                          {record.getFieldValue(field.getId()) ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
                        </>}
                        {field.getType() === 'date' && <>
                          {record.getFieldValue(field.getId()) ? moment(record.getFieldValue(field.getId())).format('LL h:ma') : ''}
                        </>}
                        {(field.getType() === 'text' || field.getType() === 'dropdown') && <>
                          {record.getFieldValue(field.getId())}
                        </>}
                      </DataCol>
                    </Link>
                  )
                )}
                </DataRow>
              )
            })}
            
          </ListContainer>
        </Page>
      </StackedChildren>
    </>
  )
})
