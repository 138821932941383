import styled from "styled-components";
import { theme } from "../theme";
import { Link } from "./Link";
import { Box } from "./Box";
import { Text } from "./Text";

interface Props {
  show: boolean;
}

const Container = styled.div<{ disabled?: boolean; }>`
  width: 100%;
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export function EmptyState(props: Props) {
  if (!props.show) {
    return <></>
  }
  return (
    <Container>
      <Text size="xl" weight='medium' color="secondary">No Records Found</Text>
    </Container>
  )
}