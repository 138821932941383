import { Box } from '../../components/Box'
import { Text } from '../../components/Text'
import { useParams } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { useCategory } from '../../hooks/useCategory'
import { useData } from '../../hooks/useData'
import { useMe } from '../../hooks/useMe'
import { observer } from 'mobx-react'
import { useVersions } from '../../hooks/useVersions'
import { useRequestedChanges } from '../../hooks/useRequestedChanges'

export const AwaitingApproval = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)

  if (!category || !me || !data) {
    return <></>;
  }

  if (!category.getSignInOut()) {
    return <></>;
  }

  if (data.isNew()) {
    return <></>
  }

  if (data.getPendingNewDocumentApproval()) {
    return (
      <Box direction='vertical' alignVertical='center' paddingTopCode='lg' paddingBottomCode='xs'>
        <Box paddingBottomCode='xxxs'>
          <Text size='lg' color='fadedWhite'>Awaiting Approval</Text>
        </Box>
      </Box>
    )
  }

  return <></>
})
