import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button'
import { Text } from '../../components/Text';
import { Box } from '../../components/Box';
import moment from 'moment';
import { useVersions } from '../../hooks/useVersions';
import { Version } from '../../models/Version';

export function Versions() {
  const { recordId, categoryId } = useParams()
  const { versions } = useVersions(categoryId, recordId)
  const navigate = useNavigate()

  if (!versions?.length) {
    return <></>
  }

  const sortedVersions = versions?.sort((a: Version ,b: Version) => {
    const dateOne = moment(a.getDate())
    const dateTwo = moment(b.getDate())
    return dateOne.isSameOrAfter(dateTwo) ? -1 : 1
  })

  function openVersion(versionId: string) {
    const url = `/${categoryId}/record/${recordId}/versions/${versionId}`
    navigate(url, { replace: true })
  }

  return (
    <>
      <Box paddingBottomCode='md'> </Box>

      {sortedVersions?.map((version, idx) => (
        <Button onClick={() => openVersion(version.getId())} key={version.getId()}>
          <Box direction='vertical' alignHorizontal='center' alignVertical='center'>

            <Text size='sm' color='white' weight={'strong'}>
              {moment(version.getDate()).format('ll')}         
            </Text>

            <Box paddingTopCode='xxxs'>
              <Text size='xs' color='fadedWhite' weight={'medium'}>
                {moment(version.getDate()).format('h:mma')}            
              </Text>
            </Box>

          </Box>
        </Button>
      ))}
    </>
  )
}
