import * as React from 'react';

import { en, TranslationSet } from '../../translations/en';

export const TranslationsContext = React.createContext<TranslationSet>(en);
const Provider = TranslationsContext.Provider;

interface Props {
  locales: { [locale: string]: TranslationSet }
  children: React.ReactNode
  defaultLocale: TranslationSet 
}

export const TranslationsProvider = (props: Props) => {
  const locale: string = window.navigator.language
  const currentLanguage: string = locale.split('-')[0].split('_')[0];

  const translations = props.locales[currentLanguage] || props.defaultLocale;
  
  return (
    <Provider value={translations}>
      {props.children}
    </Provider>
  )
}

export const useTranslations = () => {
  const context = React.useContext(TranslationsContext);

  const translate = (value: keyof typeof context) => {
    return context[value]
  }

  return { translate }
}
