import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { DataRow } from '../components/DataRow'
import { DataCol } from '../components/DataCol'
import { Link } from "../components/Link";
import { useCategories } from '../hooks/useCategories'
import { uuid } from '../helpers/UUID'
import { observer } from 'mobx-react'
import { defaultFieldCreationService } from '../services/DefaultFieldCreationService'
import { useNavigate } from 'react-router-dom'


export const CategoriesPane = observer(function () {
  const { categories } = useCategories()
  const navigate = useNavigate()

  function createNewCategory() {
    const newCategoryId = uuid.generate()
    defaultFieldCreationService.create(newCategoryId) // this will be handled async
    navigate(newCategoryId)
  }

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>

          {categories?.map(category => (
            <Link to={`${category.getId()}`} key={'edit-category-'+category.getId()}>
              <DataRow>
                <DataCol>{category.getName()}</DataCol>
              </DataRow>
            </Link>
          ))}

        </ListContainer>
        <SideBar>
          
          <Link onClick={() => createNewCategory()}>
            <Button>New Category</Button>
          </Link>

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})