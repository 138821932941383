import { useQuery } from 'react-query';
import { User } from '../models/User';
import { dataProvider } from '../realtimeDatabase';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false,
}

export const useMe = () => {
  const { 
    isLoading, 
    data: me,
    refetch,  
  } = useQuery(['me'], async () => {
    const userId = await dataProvider.getUserId()
    const mee = new User()    
    await mee.load(userId!)
    return mee
  }, options)

  return {
    me,
    refetch,
    isLoading,
  }
}