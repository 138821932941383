import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

class SubscriptionRepo {
  
  public async getNewSubscription() {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/subscriptions`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true})
      const resource = response.data.sessionUrl
      return resource
    } catch(e) {
      console.log('Failed to get subscription',e)
    }
    return null
  }

  public async updateSubscription(stripeSessionId: string) {
    try {
      const token = await dataProvider.getToken()
      await axios.post(`${config.backend.baseurl}/subscriptions/${stripeSessionId}`, {stripeSessionId}, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true})
    } catch(e) {
      console.log('Failed to update subscription',e)
    }
    return null
  }

}

export const subscriptionRepo = new SubscriptionRepo()
