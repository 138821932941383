import { dataProvider } from "../realtimeDatabase";
import { Filter } from "../models/Filter";
import axios from "axios";
import { config } from "../config";

export interface RawRecord { 
  id: string
  categoryId: string
  signedOutBy?: string | null
  hasChangeRequests?: boolean
  pendingNewDocumentApproval?: boolean
  isArchived?: boolean
  data: {
    fieldId: string
    value: string
  }[]
}

class FileRepo {

  public  async download(id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/files/${id}`, { responseType: 'blob', headers: { Authorization: `Bearer ${token}`, }})
      
      // let blob = new Blob([response.data], { type: 'meow.png' }),
      let blob = new Blob([response.data], {  }),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = "",
        disposition = response.headers["content-disposition"];

        console.log('moo',{disposition, headers:  response.headers})

    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition);

        console.log({matches , disposition, hasDesposition: disposition.indexOf("attachment") !== -1})

        if (matches != null && matches[1]) {
          console.log('What')

            filename = matches[1].replace(/['"]/g, "");
            console.log('What2',filename)

        }
    }

    let a = document.createElement("a");
    if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
    } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

      // let blob = new Blob([response.data]),
      // url = window.URL.createObjectURL(blob)

      // window.open(url);

    } catch(e) {
      console.log('Failed to get record by id',e)
    }
    return null
  }

  public async upload(id: string, file: File): Promise<RawRecord[]> {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const token = await dataProvider.getToken()
      const response = await axios.post(`${config.backend.baseurl}/files/${id}`, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }})
      const resources = response.data.resources as RawRecord[]
      return resources
    } catch(e) {
      console.log('Failed to get records',e)
    }
    return []
  }

}

export const fileRepo = new FileRepo()
