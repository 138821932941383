import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryProvider } from './providers/ReactQueryProvider';
import { TranslationsProvider } from './providers/TranslationProvider';
import { en } from './translations/en';
import { fr } from './translations/fr';
import { SiteRouter } from './pages';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
    <ReactQueryProvider>
      <TranslationsProvider 
        locales={{ en, fr }} 
        defaultLocale={en}
      >
        <SiteRouter />
      </TranslationsProvider>
    </ReactQueryProvider>
  // </React.StrictMode>
)


