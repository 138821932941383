import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { ListTextInput } from '../components/ListTextInput'
import { ListCheckbox } from '../components/ListCheckbox'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useFields } from '../hooks/useFields'
import { Field, FieldType } from '../models/Field'
import { useEffect } from 'react'
import { ListDropdown } from '../components/ListDropdown'
import { ListFileInput } from '../components/ListFileInput'

const field = new Field();
const originalField = new Field();

export const EditFieldPage = observer(function () {
  const { fieldId, editCategoryId } = useParams()
  const { refetch: refetchFields, fields } = useFields(editCategoryId)
  const navigate = useNavigate()

  useEffect(() => {
    if (fieldId && editCategoryId) {
      field.load(editCategoryId, fieldId)
      originalField.load(editCategoryId, fieldId)
    }
  }, [fieldId, editCategoryId])

  function hasChanges(): boolean {
    if (originalField.getName() !== field.getName()) return true
    if (originalField.getWidth() !== field.getWidth()) return true
    if (originalField.getType() !== field.getType()) return true
    if (originalField.getDropdownOptions() !== field.getDropdownOptions()) return true
    return false
  }

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
        <ListTextInput title="Name" value={field?.getName()} onChange={(newName) => field?.setName(newName)} />
        <ListTextInput title="Width" value={`${field?.getWidth() ? field?.getWidth() : ''}`} placeholder="200" onChange={(newWidth) => field?.setWidth(parseInt(newWidth) || 0)} />
        
        {!field.getReservedType() && (
          <ListDropdown 
            label='Type' 
            onChange={(newType: FieldType) => field?.setType(newType)} 
            value={field?.getType()} 
            options={[ 
              { label: 'Text', value: 'text' },
              { label: 'Dropdown', value: 'dropdown' },
              { label: 'Number', value: 'number' },
              { label: 'Checkbox', value: 'checkbox' },
              { label: 'File', value: 'file' },
            ]} 
          />
        )}

        {field?.getType() === 'dropdown' && (
          <ListTextInput title="Dropdown options (comma separated)" value={`${field?.getDropdownOptions().join(',')}`} placeholder="" onChange={(options) => field?.setDropdownOptions(options.split(','))} />
        )}

        {!field.getReservedType() && (
          <ListCheckbox label="Required" value={field?.getRequired()} onClick={(newValue) => field?.setRequired(newValue)} />
        )}
        <ListCheckbox label="Show in list" value={field?.getShowInList()} onClick={(newValue) => field?.setShowInList(newValue)} />

        </ListContainer>
        <SideBar>
          
          <Button disabled={!field.canSave() || !hasChanges()} onClick={async () => {
            await field?.save()
            refetchFields()
            navigate(-1)
          }}>
            Save
          </Button>

          {!field.getReservedType() && (
            <Button onClick={async () => {
              await field.setDeleted(true)
              await field.save()
              await refetchFields()
            }}>Delete</Button>
          )}

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})
