import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.8);
`

export function Background(props: Props) {
  return (
    <Container>
      {props.children}
    </Container>
  )
}