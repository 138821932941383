export const theme = {
  palette: {
    primary: 'black',
    secondary: 'grey',
    accent: '#e73f49',
    lineColor: '#f0f0f0',
    white: 'white',
    fadedWhite: 'rgba(255,255,255,0.7)',
  },
  screenSizes: {
    mobile: 800,
    tablet: 1000,
  },
  spacing: {
    xxxl: '65px',
    xxl: '40px',
    xl: '25px',
    lg: '20px',
    md: '16px',
    sm: '14px',
    xs: '12px',
    xxs: '10px',
    xxxs: '3px',
  },
  typography: {
    textSizes: {
      xxxl: '65px',
      xxl: '32px',
      xl: '25px',
      lg: '20px',
      md: '16px',
      sm: '14px',
      xs: '12px',
      xxs: '10px',
    },
    mobileTextSizes: {
      xxxl: '35px',
      xxl: '25px',
      xl: '20px',
      lg: '16px',
      md: '12px',
      sm: '10px',
      xs: '7px',
      xxs: '5px',
    },
    weights: {
      light: '300',
      normal: '400',
      medium: '500',
      strong: '700',
      extra: '900',
    }
  },
}