import { makeAutoObservable, toJS } from "mobx"
import { RawUserType, userTypeRepo } from "../repositories/UserTypeRepo";


export class UserType {
  private id: string = ''
  private name: string = ''
  private permissions: {
    [key: string]: boolean;
  } = {}
  private deleted = false;

  public getDeleted(): boolean {
    return this.deleted
  }

  public setDeleted(deleted: boolean): void {
    this.deleted = deleted
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  public getId() {
    return this.id
  }

  private setId(id: string) {
    this.id = id
  }

  public setName(name: string) {
    this.name = name
  }

  public getName() {
    return this.name
  }

  public hasPermission(permission: string): boolean {
    return this.permissions[permission] || false
  }

  public enablePermission(permission: string): void {
    this.permissions[permission] = true
  }

  public disablePermission(permission: string): void {
    this.permissions[permission] = false
  }

  public setPermissions(permissions: { [key: string]: boolean; }) {
    this.permissions = permissions
  }

  public getPermissions() {
    return this.permissions
  }

  private reset() {
    this.fromRawData(null)
  }

  public async load(id: string) {
    this.reset()
    this.setId(id)
    const userType = await userTypeRepo.getById(id)
    if (userType) {
      this.fromRawData(userType)
    }
  }

  public fromRawData(userType: RawUserType | null) {
    this.setId(userType?.id || '')
    this.setName(userType?.name || '')
    this.setPermissions(userType?.permissions || {})
    this.setDeleted(userType?.deleted || false)
  }

  public canSave() {
    if (!this.getName().trim()) return false
    return true
  }

  public async save() {
    if (!this.canSave()) return
    await userTypeRepo.save({
      id: this.getId(),
      name: this.getName(),
      permissions: this.getPermissions(),
      deleted: this.getDeleted(),
    })
  }

  public async delete() {
    
  }

}
