import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { useData } from '../../hooks/useData'
import { observer } from "mobx-react";
import { useRecordSearch } from '../../hooks/useRecordSearch';

export const DeleteButton = observer(function () {
  const { recordId, categoryId } = useParams()
  const { data } = useData(categoryId, recordId)
  const navigation = useNavigate()
  const { refetch: refetchSearch } = useRecordSearch()

  if (!data) {
    return <></>
  }

  if (data.isNew()) {
    return <></>
  }

  return (
    <Button onClick={() => { 
      data.setDeleted(true)
      data.save()
      navigation(-1)
      refetchSearch()
    }}>
      Delete
    </Button> 
  )
})
