import { Field } from "./Field";
import { makeAutoObservable } from "mobx";
import { RawCategory, categoryRepo } from "../repositories/CategoryRepo";

export class Category {
  private id: string = ''
  private name: string = ''
  private fields: Field[] = []
  private isDefault: boolean = false
  private requireApproval: boolean = false
  private signInOut: boolean = false
  private deleted = false;

  public getDeleted(): boolean {
    return this.deleted
  }

  public setDeleted(deleted: boolean): void {
    this.deleted = deleted
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  public getRequireApproval(): boolean {
    return this.requireApproval
  }

  public setRequireApproval(requireApproval: boolean) {
    this.requireApproval = requireApproval
  }

  public getSignInOut(): boolean {
    return this.signInOut
  }

  public setSignInOut(signInOut: boolean) {
    this.signInOut = signInOut
  }

  public setId(id: string) {
    this.id = id
  }

  public getId() {
    return this.id
  }

  public setName(name: string) {
    this.name = name
  }

  public getName() {
    return this.name
  }

  public addField(field: Field) {
    this.fields.push(field)
  }

  public getFields() {
    return this.fields
  }

  public setDefault(): void {
    this.isDefault = true
  }

  public getDefault(): boolean {
    return this.isDefault
  }

  private reset() {
    this.fromRawData(null)
  }

  public async load(id: string) {
    this.reset()
    this.setId(id)
    const category = await categoryRepo.getById(id)
    if (category) {
      this.fromRawData(category)
    }
  }

  public canSave(): boolean {
    if (!this.getName().trim()) return false
    return true
  }

  public fromRawData(data: RawCategory | null): void {
    this.setId(data?.id || '')
    this.setName(data?.name || '')
    this.setRequireApproval(data?.requireApproval || false)
    this.setSignInOut(data?.signInOut || false)
    this.setDeleted(data?.deleted || false)
  }

  public async save() {
    if (!this.canSave()) return
    await categoryRepo.save({
      id: this.getId(),
      name: this.getName(),
      requireApproval: this.getRequireApproval(),
      signInOut: this.getSignInOut(),
      deleted: this.getDeleted(),
    })
  }

}