import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import { SpaceBetween } from "../../components/SpaceBetween";
import { Link } from "../../components/Link";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";

const TopBar = styled.div`
  font-size: ${theme.typography.textSizes.xl};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
`

const MaxWidth = styled.div`
  max-width: 1200px;
  width: 100%;
  font-size: ${theme.typography.textSizes.xl};
  padding: ${theme.spacing.xl};
`

const SignUpButton = styled.div`
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
  font-size: ${theme.typography.textSizes.sm};
  border-radius: 50px;
  background-color: ${theme.palette.accent};
  color: white;
  text-align: center;
`


export const Header = observer(function () {
  const navigate = useNavigate()

  async function login() {
    const user = await firebase.auth().currentUser
    if (user) {
      navigate('/dashboard')
    } else {
      navigate('/signin')
    }
  }

  return (
    <TopBar>
      <MaxWidth>
        <SpaceBetween direction="horizontal">

          <Box direction="horizontal" alignVertical="center" >
            <img src={require('../../assets/logo7@3x.png')} width={40} alt='logo' style={{ marginRight: theme.spacing.xs }}  />
            <Text size="lg" weight="medium" color="black">Record Rooster</Text>
          </Box>

          <Box direction="horizontal" alignVertical="center">

            <Link  onClick={() => login()}>
              <Box paddingCode="sm">
                <Text size="lg" color="black">Login</Text>
              </Box>
            </Link>

            <Link onClick={() => login()}>
              <Box paddingLeftCode="sm" paddingTopCode="sm" paddingBottomCode="sm">
                <SignUpButton>Start Free Trial</SignUpButton>
              </Box>
            </Link>

          </Box>

        </SpaceBetween>
      </MaxWidth>
    </TopBar>
  )
})
