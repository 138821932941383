import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface RawUser { 
  id: string;
  name: string;
  bookmarks: string[];
  type: string;
  email: string;
  deleted: boolean;
}

class UserRepo {

  public  async getById(id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/users/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawUser
      return resource
    } catch(e) {
      console.log('Failed to get user by id',e)
    }
    return null
  }

  public  async signUp(id: string, inviteToken?: string | null) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/users/${id}?inviteToken=${inviteToken || ''}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawUser
      return resource
    } catch(e) {
      console.log('Failed to get user by id',e)
    }
    return null
  }

  public async getAll(): Promise<RawUser[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/users`, { headers: { Authorization: `Bearer ${token}` }})
      const resources = response.data.resources as RawUser[]
      return resources
    } catch(e) {
      console.log('Failed to get users',e)
    }
    return []
  }

  public async save(data: RawUser) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/users/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawUser
      return resource
    } catch(e) {
      console.log('Failed to save user',e)
    }
    return null
  }

}

export const userRepo = new UserRepo()
