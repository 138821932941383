import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface RawUserType { 
  id: string;
  name: string;
  deleted: boolean;
  permissions: {
    [key: string]: boolean;
  };
}

class UserTypeRepo {

  public  async getById(id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/user-types/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawUserType
      return resource
    } catch(e) {
      console.log('Failed to get user type by id',e)
    }
    return null
  }

  public async getAll(): Promise<RawUserType[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/user-types`, { headers: { Authorization: `Bearer ${token}` }})
      console.log({response})
      const resources = response.data.resources as RawUserType[]
      return resources
    } catch(e) {
      console.log('Failed to get user types',e)
    }
    return []
  }

  public  async save(data: RawUserType) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/user-types/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawUserType
      return resource
    } catch(e) {
      console.log('Failed to save user types',e)
    }
    return null
  }

}

export const userTypeRepo = new UserTypeRepo()
