import styled from "styled-components";
import { theme } from "../theme";

interface Props {
  children: React.ReactNode;
  titleRow?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Container = styled.div<{ titleRow?: boolean; disabled?: boolean; }>`
  font-size: ${theme.typography.textSizes.xs};
  display: flex;
  flex-direction: row;
  transition: all 0.2s;
  min-width: 100%;
  background-color: white;
  font-weight: ${props => props.titleRow ? theme.typography.weights.strong : theme.typography.weights.normal};
  cursor: ${props => props.titleRow || props.disabled ? 'default' : 'pointer'};
  &:hover {
    background-color: ${props => props.disabled ? 'white' : '#f7f7f7'};    
  }
`

const BottomBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0,0,0,0.1);
`

export function DataRow(props: Props) {
  return (
    <>
    <Container disabled={props.disabled} titleRow={props.titleRow} onClick={props.onClick}>
      {props.children}
    </Container>
    <BottomBorder />

    </>
  )
}