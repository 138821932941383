import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  direction: 'vertical' | 'horizontal';
}

const Container = styled.div<{ direction: Props['direction'] }>`
  display: flex;
  justify-content: space-between; 
  flex-direction: ${props => props.direction === 'vertical' ? 'column' : 'row'};
  height: 100%;
  width: 100%;
  align-items: center;
`

export function SpaceBetween(props: Props) {
  return (
    <Container direction={props.direction}>
      {props.children}
    </Container>
  )
}