import styled from "styled-components";
import { theme } from "../theme";
import { KeyboardArrowDown } from '@mui/icons-material';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  value: Option['value'];
  onChange: (newValue: Option['value']) => void;
}

const Dropdown = styled.select`
  font-size: ${theme.typography.textSizes.sm};
  color: rgba(255,255,255,0.8);
  justify-content: center;
  cursor: pointer;
  font-weight: ${theme.typography.weights.strong};
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  padding-right: ${theme.spacing.xxl};
  width: 100%;
`

const Container = styled.div`
  background-color: rgba(255,255,255,0.05);
  font-size: ${theme.typography.textSizes.sm};
  color: rgba(255,255,255,0.8);
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: ${theme.spacing.xxs};
  border-radius: 12px;
  transition: all 0.2s;
  line-height: 150%;
  font-weight: ${theme.typography.weights.strong};
  user-select: none;
  &:hover {
    color: white;
    background-color: rgba(255,255,255,0.09);
  }
`

const DownArrowContainer = styled(KeyboardArrowDown)`
  pointer-events: none;
  position: absolute;
  right: ${theme.spacing.sm};
  height: 100%;
`

export function SidebarDropdown(props: Props) {
  return (
    <Container>
      <Dropdown value={props.value} onChange={(event) => props.onChange(event.target.value)}>
        {props.options.map((option) => (
          <option key={'sidebar-dropdown-'+option.value} value={option.value} style={{ fontWeight: 'normal' }}>{option.label}</option>
        ))}
      </Dropdown>
      <DownArrowContainer>
        <KeyboardArrowDown />
      </DownArrowContainer>
    </Container>
  )
}