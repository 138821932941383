import { Button } from '../../components/Button'
import { useParams } from 'react-router-dom'
import { useCategory } from '../../hooks/useCategory'
import { useMe } from '../../hooks/useMe'
import { useData } from '../../hooks/useData'
import { Version } from '../../models/Version'
import { observer } from 'mobx-react'
import { useAllData } from '../../hooks/useAllData'
import { useVersions } from '../../hooks/useVersions'
import { useFields } from '../../hooks/useFields'
import { useUsersType } from '../../hooks/useUsersType'
import { useRecordSearch } from '../../hooks/useRecordSearch'
import { Permissions, injectCategoryId } from '../../shared/Permissions'

export const SaveButton = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)
  const { refetch: refetchVersions } = useVersions(categoryId, recordId)
  const { recordSearch } = useRecordSearch()
  const { fields } = useFields(categoryId)
  const { userType } = useUsersType(me?.getType() || '')

  if (!category || !me || !data) {
    return <></>;
  }

  if (category.getRequireApproval() && !userType?.hasPermission(injectCategoryId(Permissions.CATEGORY_SAVE_WITHOUT_APPROVAL, categoryId || ''))) {
    return <></>;
  } 
  
  return (
    <Button
      disabled={!data.hasEdits() || !data.canSave(fields || [])} 
      onClick={async () => {
        await data.saveEdits()
        refetchVersions()
        recordSearch?.search()
      }}
    >
      Save
    </Button>
  )
})
