import { useQuery } from 'react-query';
import { versionRepo } from '../repositories/VersionRepo';
import { Version } from '../models/Version';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useVersions = (categoryId?: string, recordId?: string) => {
  const { 
    isLoading, 
    data: versions,
    refetch,  
  } = useQuery(['versions', categoryId, recordId], async () => {
    if (!recordId || !categoryId) return null

    const versionData = await versionRepo.getByRecordId(categoryId, recordId)
    const versions = versionData.map(row => {
      const version = new Version();
      version.fromRawData(row) 
      return version
    })

    return versions
  }, options)

  return {
    versions,
    refetch,
    isLoading,
  }
}