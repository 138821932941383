import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import moment from "moment";
import { SpaceBetween } from "../../components/SpaceBetween";
import { Link } from "../../components/Link";

const FooterElement = styled.div`
  border-radius: 10px;
  background-color: rgba(0,0,0,0.05);
  margin-top: ${theme.spacing.xxxl};
  padding: ${theme.spacing.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MaxWidth = styled.div`
  max-width: 1200px;
  width: 100%;
  font-size: ${theme.typography.textSizes.xl};
  padding: ${theme.spacing.xl};
`

export const Footer = observer(function () {
  return (
    <FooterElement>

      <MaxWidth>
        <SpaceBetween direction="horizontal">

          <Box direction="vertical" paddingTopCode="xs">
            <Box direction="horizontal" alignVertical="center" paddingBottomCode="md" >
              <Box paddingRightCode="xs">
                <img src={require('../../assets/logo7@3x.png')} width={30} alt='logo' />
              </Box>
              <Text size="lg" weight="medium" color="black">Record Rooster</Text>
            </Box>
            <Box>
              <Text size="xs" weight="medium" color={'secondary'}>
                © Record Rooster {moment().format('yyyy')}
              </Text>
            </Box>
          </Box>

          <Box paddingTopCode="xs" direction="vertical">

            <Link to="/contact">
              <Text size="md" weight="medium" color={'secondary'}>
                Contact Us
              </Text>
            </Link>

            <Link to="/terms">
              <Text size="md" weight="medium" color={'secondary'}>
                Terms of Service
              </Text>
            </Link>

            <Link to="/privacy">
              <Text size="md" weight="medium" color={'secondary'}>
                Privacy Policy
              </Text>
            </Link>

          </Box>

        </SpaceBetween>
      </MaxWidth>

      

    </FooterElement>
  )
})
