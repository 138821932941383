import styled from "styled-components";
import { theme } from "../theme";

interface Props {
  children: React.ReactNode;
  size: keyof typeof theme.typography.textSizes;
  weight?: keyof typeof theme.typography.weights;
  color?: keyof typeof theme.palette | string;
  align?: 'left' | 'center' | 'right';
}

const Container = styled.span<{ selectable?: boolean; size: Props['size']; align: Props['align']; weight: Props['weight']; color: Props['color']; }>`
  font-weight: ${(props) => theme.typography.weights[props.weight || 'normal']};
  font-size: ${(props) => theme.typography.textSizes[props.size]};
  color: ${(props) => theme.palette[(props.color as keyof typeof theme.palette) || 'primary'] || props.color};
  text-align: ${(props) => props.align || 'left'};
  line-height: 140%;
  @media (max-width: ${theme.screenSizes.tablet}px) {
    font-size: ${(props) => theme.typography.mobileTextSizes[props.size]};
  }
`

export function Text(props: Props) {
  return (
    <Container align={props.align} size={props.size} weight={props.weight} color={props.color}>
      {props.children}
    </Container>
  )
}