import { StackedChildren } from '../../components/StackedChildren'
import { SideBar } from '../../components/SideBar'
import { ListContainer } from '../../components/ListContainer'
import { Popup } from '../../components/Popup'
import { ListTextInput } from '../../components/ListTextInput'
import { SignedOutBy } from './SignedOutBy'
import { SignOutButton } from './SignOutButton'
import { SignInButton } from './SignInButton'
import { BookmarkButton } from './BookmarkButton'
import { RequestChangeButton } from './RequestChangeButton'
import { SaveButton } from './SaveButton'
import { Versions } from './Versions'
import { useParams } from 'react-router-dom'
import { useData } from '../../hooks/useData'
import { observer } from 'mobx-react'
import { RequestedChanges } from './RequestedChanges'
import { DenyButton } from './DenyButton'
import { ApproveButton } from './ApproveButton'
import { BackButton } from './BackButton'
import { useFields } from '../../hooks/useFields'
import { useEffect } from 'react'
import { useRequestedChange } from '../../hooks/useRequestedChange'
import { useVersion } from '../../hooks/useVersion'
import { VersionsButton } from './VersionsButton'
import { Field } from '../../models/Field'
import { ListDropdown } from '../../components/ListDropdown'
import { ListCheckbox } from '../../components/ListCheckbox'
import { ListDateInput } from '../../components/ListDateInput'
import { AwaitingApproval } from './AwaitingApproval'
import { ArchiveButton } from './ArchiveButton'
import { toJS } from 'mobx'
import { ListFileInput } from '../../components/ListFileInput'
import { DeleteButton } from './DeleteButton'

export const EditDocument = observer(function () {
  const { categoryId, recordId, versionId, requestedChangeId } = useParams()
  const { data } = useData(categoryId, recordId);
  const { fields } = useFields(categoryId)
  const { requestedChange } = useRequestedChange(categoryId, recordId, requestedChangeId)
  const { version } = useVersion(categoryId, recordId, versionId)
  const sortedFields = fields?.sort((a,b) => { return (a.getSortOrder() || 0) > (b.getSortOrder() || 0) ? 1 : -1 })

  useEffect(() => {
    if (data && categoryId && fields) {
      data.setCategoryId(categoryId)
      data.setDateCreated(fields)
    }
  }, [data, categoryId, fields])

  useEffect(() => {
    if (data?.hasEdits() && fields) {
      data.setDateChanged(fields)
    }
  }, [data?.hasEdits(), fields])

  function getCurrentValue(fieldId: string): any {
    if (versionId && !version) {
      return ''
    }
    
    if (requestedChangeId && !requestedChange) {
      return ''
    }

    if (requestedChange?.getFieldValue(fieldId)) {
      return requestedChange?.getFieldValue(fieldId) || ''
    }

    if (version?.getFieldValue(fieldId)) {
      return version?.getFieldValue(fieldId) || ''
    }

    if (data?.hasEditValue(fieldId)) {
      return data?.getEditValue(fieldId) || ''
    }

    return data?.getFieldValue(fieldId) || ''
  }

  function shouldFallbackToNonExistingValue(options: string[], field: Field) {
    return !options.includes(getCurrentValue(field.getId()))
  }

  function fieldFactory(field: Field) {
    const currentValue = getCurrentValue(field.getId())

    if (field.getType() === 'checkbox') {
      return (
        <ListCheckbox 
          key={'edit-data-field-'+field.getId()}
          label={field.getName()} 
          value={currentValue} 
          onClick={(newValue: boolean) => data?.setEditValue(field.getId(), field.formatValueByType(newValue))}
        />
      )
    }

    if (field.getType() === 'dropdown') {
      const options = [ ...field.getDropdownOptions() ]
      if (shouldFallbackToNonExistingValue(options, field)) {
        options.push(currentValue)
      }

      return (
        <ListDropdown 
          key={'edit-data-field-'+field.getId()}
          id={field.getId()}
          label={field.getName()} 
          onChange={(newValue: string) => data?.setEditValue(field.getId(), newValue)} 
          value={currentValue} 
          options={options.map(option => ({ label: option, value: option }))} 
          required={field.getRequired()}
          highlight={data?.hasEditValue(field.getId()) && data?.getEditValue(field.getId()) !== data?.getFieldValue(field.getId())}
          disabled={Boolean(requestedChangeId || versionId)}  
        />
      )
    }

    if (field.getType() === 'date') {
      return (
        <ListDateInput 
          key={'edit-data-field-'+field.getId()}
          id={field.getId()}
          title={field.getName()} 
          value={getCurrentValue(field.getId())} 
          onChange={(newValue: string) => data?.setEditValue(field.getId(), field.formatValueByType(newValue))}
          highlight={data?.hasEditValue(field.getId()) && data?.getEditValue(field.getId()) !== data?.getFieldValue(field.getId())}
          disabled={Boolean(requestedChangeId || versionId || field.getReservedType())}
          required={field.getRequired()}
        />
      )
    }

    if (field.getType() === 'file') {
      return (
        <ListFileInput 
          key={'edit-data-field-'+field.getId()}
          id={field.getId()}
          title={field.getName()} 
          value={getCurrentValue(field.getId())} 
          onChange={(newValue: string) => data?.setEditValue(field.getId(), field.formatValueByType(newValue))}
          highlight={data?.hasEditValue(field.getId()) && data?.getEditValue(field.getId()) !== data?.getFieldValue(field.getId())}
          disabled={Boolean(requestedChangeId || versionId || field.getReservedType())}
          required={field.getRequired()}
        />
      )
    }

    return (
      <ListTextInput 
        key={'edit-data-field-'+field.getId()}
        id={field.getId()}
        title={field.getName()} 
        value={getCurrentValue(field.getId())} 
        onChange={(newValue: string) => data?.setEditValue(field.getId(), field.formatValueByType(newValue))}
        highlight={data?.hasEditValue(field.getId()) && data?.getEditValue(field.getId()) !== data?.getFieldValue(field.getId())}
        disabled={Boolean(requestedChangeId || versionId || field.getReservedType())}
        required={field.getRequired()}
      />
    )
  }

  return (
    <StackedChildren>
      <Popup>

        <ListContainer>
          {sortedFields?.map(field => fieldFactory(field))}
        </ListContainer>

        {versionId && (
          <SideBar>
          
            <BackButton />

            <Versions />

          </SideBar>
        )}

        {requestedChangeId && (
          <SideBar>
          
            <ApproveButton />
            <DenyButton />
            <BackButton />

          </SideBar>
        )}

        {(!versionId && !requestedChangeId) && (
          <SideBar>

            <AwaitingApproval />
          
            <SignedOutBy />

            <SignOutButton />
            <SignInButton />
            <RequestChangeButton />
            <SaveButton />
            <BookmarkButton />
            <ArchiveButton />
            <DeleteButton />
            <VersionsButton />

            <RequestedChanges />

          </SideBar>
        )}

      </Popup>
    </StackedChildren>
  )
})
