import styled from "styled-components";
import { keyframes } from 'styled-components'
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactElement;
  dontAnimateIn?: boolean;
}

const animateInOpacity = keyframes`
  from { background-color: rgba(0,0,0,0); }
  to { background-color: rgba(0,0,0,0.5); }
`

const animateIn = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0%); }
`

const Darken = styled.div<{ dontAnimateIn?: boolean; }>`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.3s;
  animation-duration: 0.3s;
  animation-name: ${props => props.dontAnimateIn ? '' : animateInOpacity};
`

const InnerContainer = styled.div<{ dontAnimateIn?: boolean; }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.3s;
  animation-duration: 0.3s;
  overflow: hidden;
  border-radius: 10px;
  animation-name: ${props => props.dontAnimateIn ? '' : animateIn};
`

export function StackedChildren(props: Props) {
  const navigate = useNavigate();
  return (
    <Darken dontAnimateIn={props.dontAnimateIn} className="stacked" onClick={() => navigate(-1)}>
      <InnerContainer dontAnimateIn={props.dontAnimateIn} className="stacked-child" >
        {props.children}
      </InnerContainer>
    </Darken>
  )
}