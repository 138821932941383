import { uuid } from "../helpers/UUID"
import { Field } from "../models/Field"

class DefaultFieldCreationService {

  public async create(categoryId: string): Promise<void> {
    await this.createDateCreatedField(categoryId)
    await this.createDateChangedField(categoryId)
  }

  private async createDateCreatedField(categoryId: string) {
    const dateCreatedField = new Field()
    dateCreatedField.setId(uuid.generate())
    dateCreatedField.setName('Date Created')
    dateCreatedField.setCategoryId(categoryId)
    dateCreatedField.setWidth(250)
    dateCreatedField.setReservedType('dateCreated')
    dateCreatedField.setType('date')
    dateCreatedField.setSortOrder(0)
    await dateCreatedField.save()
  }

  private async createDateChangedField(categoryId: string) {
    const dateChangedField = new Field()
    dateChangedField.setId(uuid.generate())
    dateChangedField.setName('Date Changed')
    dateChangedField.setCategoryId(categoryId)
    dateChangedField.setWidth(250)
    dateChangedField.setReservedType('dateChanged')
    dateChangedField.setType('date')
    dateChangedField.setSortOrder(1)
    await dateChangedField.save()
  }

}

export const defaultFieldCreationService = new DefaultFieldCreationService()