import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface RawCategory { 
  id: string;
  name: string;
  requireApproval: boolean;
  signInOut: boolean;
  deleted: boolean;
}

class CategoryRepo {
  
  public async getById(id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${id}`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true})
      const resource = response.data.resource as RawCategory
      return resource
    } catch(e) {
      console.log('Failed to get category by id',e)
    }
    return null
  }

  public async getAll(): Promise<RawCategory[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true})
      const resources = response.data.resources as RawCategory[]
      return resources
    } catch(e) {
      console.log('Failed to get categories',e)
    }
    return []
  }

  public  async save(data: RawCategory) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/categories/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawCategory
      return resource
    } catch(e) {
      console.log('Failed to save category',e)
    }
    return null
  }

}

export const categoryRepo = new CategoryRepo()
