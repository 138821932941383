import { useQuery } from 'react-query';
import { RequestedChange } from '../models/RequestedChange';
import { requestedChangeRepo } from '../repositories/RequestedChangeRepo';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useRequestedChanges = (categoryId?: string, recordId?: string) => {
  const { 
    isLoading, 
    data: requestedChanges,
    refetch,  
  } = useQuery(['requestedChanges', categoryId, recordId], async () => {
    if (!recordId || !categoryId) return null

    const requestedChangeData = await requestedChangeRepo.getByRecordId(categoryId, recordId)
    const requestedChanges = requestedChangeData.map(row => {
      const requestChange = new RequestedChange();
      requestChange.fromRawData(row) 
      return requestChange
    })

    return requestedChanges
  }, options)

  return {
    requestedChanges,
    refetch,
    isLoading,
  }
}