import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { DataRow } from '../components/DataRow'
import { DataCol } from '../components/DataCol'
import { Link } from "../components/Link";
import { useUsers } from '../hooks/useUsers'
import { uuid } from '../helpers/UUID'
import { observer } from 'mobx-react'
import { useUsersType } from '../hooks/useUsersType'
import { useMe } from '../hooks/useMe'
import { Permissions } from '../shared/Permissions'

export const UserPanel = observer(function () {
  const { users } = useUsers()
  const { me } = useMe()
  const { userType } = useUsersType(me?.getType() || '')

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
          {users?.map(user => (
            <Link to={`${user.getId()}`} key={`edit-user-${user.getId()}`}>
              <DataRow>
                <DataCol>{user.name}</DataCol>
              </DataRow>
            </Link>
          ))}

        </ListContainer>
        <SideBar>
          
          <Button to={`${uuid.generate()}`}>New User</Button>
          {userType?.hasPermission(Permissions.SETTINGS) && (
            <Button to={`user-types`}>User Types</Button>
          )}

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})