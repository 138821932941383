import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { ListTextInput } from '../components/ListTextInput'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { User } from '../models/User'
import { useUsers } from '../hooks/useUsers'
import { ListDropdown } from '../components/ListDropdown'
import { useUsersTypes } from '../hooks/useUsersTypes'

const user = new User()
const originalUser = new User()

export const EditUserPage = observer(function () {
  const { userId } = useParams()
  const navigate = useNavigate()
  const { refetch: refetchUsers } = useUsers()
  const { userTypes } = useUsersTypes()

  useEffect(() => {
    if (userId) {
      user.load(userId)
      originalUser.load(userId)
    }
  }, [userId])

  function hasChanges(): boolean {
    if (originalUser.getName() !== user.getName()) return true
    if (originalUser.getType() !== user.getType()) return true
    if (originalUser.getEmail() !== user.getEmail()) return true
    return false
  }

  const userTypesWithoutDeletedUnlessSelected = userTypes?.filter(userType => {
    return !userType.getDeleted() || user.getType() === userType.getId()
  }) || []

  const userTypeOptions = userTypesWithoutDeletedUnlessSelected.map(userType => {
    return { 
      label: userType.getName(), 
      value: userType.getId() 
    }
  })

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
          <ListTextInput 
            title="Display name" 
            required
            value={user.getName()} 
            onChange={(newName) => user.setName(newName)} 
          />

          <ListTextInput 
            title="Email" 
            value={user.getEmail()} 
            onChange={(newEmail) => user.setEmail(newEmail)} 
          />

          <ListDropdown 
            label='User Type'
            required
            value={user.getType()}
            onChange={(newValue) => user.setType(newValue)}
            options={[ { label: 'Select one', value: '' }, ...userTypeOptions]
            }
          />

        </ListContainer>
        <SideBar>
          
          <Button 
            disabled={!hasChanges() || !user.canSave()}
            onClick={async () => {
              await user.save()
              refetchUsers()
              navigate(-1)
            }}
          >
            Save
          </Button>

          <Button onClick={() => {
            user.delete()
            user.save()
            navigate(-1)
            refetchUsers()
          }}>
            Delete
          </Button>
          
        </SideBar>
      </Popup>
    </StackedChildren>
  )
})
