import { useQuery } from 'react-query';
import { Record } from '../models/Record';
import { useAllData } from './useAllData';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useData = (categoryId?: string, id?: string) => {
  const { data: allData, isLoading: isLoadingAllData } = useAllData(categoryId)

  const { 
    isLoading, 
    data,
    refetch,  
  } = useQuery(['useData', categoryId, id, isLoadingAllData], async () => {
    if (!id || !categoryId) return null 
    if (isLoadingAllData) return null

    // Attempt to use already loaded version first 
    const recordInAllData = allData?.find(row => row.getId() === id)
    if (recordInAllData) {
      return recordInAllData
    }

    const record = new Record()
    await record.load(categoryId, id)
    
    return record
  }, options)

  return {
    data,
    refetch,
    isLoading,
  }
}