import { useQuery } from 'react-query';
import { sftpRepo } from '../repositories/SftpRepo';
import { Sftp } from '../models/Sftp';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useSftp = () => {
  const { 
    isLoading, 
    data: sftp,
    refetch,  
  } = useQuery(['sftp'], async () => {
    const raw = await sftpRepo.get()
    const sftp = new Sftp();
    sftp.fromRawData(raw) 

    return sftp
  }, options)

  return {
    sftp,
    refetch,
    isLoading,
  }
}