import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button'
import { ListTitle } from '../../components/ListTitle'
import { useData } from '../../hooks/useData';
import { Text } from '../../components/Text';
import { Box } from '../../components/Box';
import moment from 'moment';
import { useMe } from '../../hooks/useMe';
import { useRequestedChanges } from '../../hooks/useRequestedChanges';
import { observer } from 'mobx-react';
import { useUsersType } from '../../hooks/useUsersType';
import { Permissions, injectCategoryId } from '../../shared/Permissions';

export const RequestedChanges = observer(function () {
  const { recordId, categoryId } = useParams()
  const { data } = useData(categoryId, recordId)
  const { me } = useMe()
  const { requestedChanges } = useRequestedChanges(categoryId, recordId)
  const { userType } = useUsersType(me?.getType() || '')

  const pendingRequestedChanges = requestedChanges?.filter(requestedChange => requestedChange.getStatus() === 'pending')

  if (!data) {
    return <></>
  }

  if (!userType?.hasPermission(injectCategoryId(Permissions.CATEGORY_REVIEW, categoryId || ''))) {
    return <></>
  }

  if (!pendingRequestedChanges?.length) {
    return <></>
  }

  const sortedRequestedChanges = requestedChanges?.sort((a,b) => {
    const dateOne = moment(a.getDate())
    const dateTwo = moment(b.getDate())
    return dateOne.isSameOrAfter(dateTwo) ? -1 : 1
  })

  return (
    <>
      <ListTitle title="Requested Changes" />

      {sortedRequestedChanges?.map((requestedChange) => (
        <Button to={`requested-changes/${requestedChange.getId()}`} key={requestedChange.getId()}>
          <Box direction='vertical' alignHorizontal='center' alignVertical='center'>

            <Text size='sm' color='white' weight={'medium'}>
              {moment(requestedChange.getDate()).format('ll')}            
            </Text>

            <Text size='xs' color='fadedWhite' weight={'light'}>
              {moment(requestedChange.getDate()).format('h:mma')}
            </Text>

          </Box>
        </Button>
      ))}
    </>
  )
})
