import styled from "styled-components";
import { theme } from "../theme";
import { uuid } from "../helpers/UUID";
import { FileUpload, useFileUpload } from 'use-file-upload'
import { useState } from "react";
import { Spinner } from "./Spinner";
import { fileRepo } from "../repositories/FileRepo";

interface Props {
  id?: string;
  title: string;
  value?: string;
  onChange?: (newValue: string) => void;
  highlight?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
}

const Container = styled.div<{ highlight?: boolean; }>`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  flex-direction: column;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  transition: all 0.3s;
  ${props => props.highlight ? 'background-color: #E8F4FE;' : ''}
`

const TitleContainer = styled.div`
  font-size: ${theme.typography.textSizes.sm};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xxs};
  padding-top: ${theme.spacing.xxs};
`

const TextInput = styled.input`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xxs};
  padding-right: ${theme.spacing.xxs};
  padding-top: ${theme.spacing.xxxs};
  padding-bottom: ${theme.spacing.xxs};
  border: none;
  background-color: transparent;
`

const Required = styled.div`
  color: red;
  padding-left: ${theme.spacing.xxxs};
`

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const DownloadButton = styled.div`
  height: 40px;
  background-color: #5194d2;
  padding: ${theme.spacing.sm};
  margin-top: ${theme.spacing.xs};
  margin-left: ${theme.spacing.sm};
  margin-right: ${theme.spacing.md};
  margin-bottom: ${theme.spacing.md};
  border-radius: 10px;
  line-height: 0px;
  text-align: center;
  color: white;
  font-weight: ${theme.typography.weights.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
`

const ReplaceButton = styled.div`
  padding-right: ${theme.spacing.md};
  font-size: ${theme.typography.textSizes.md};
  align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`


export function ListFileInput(props: Props) {
  const [file, selectFile] = useFileUpload()
  const [isUploading, setIsUploading] = useState(false)

  const upload = async (file: FileUpload | [FileUpload]) => {
    setIsUploading(true)
    const fileId = uuid.generate()
    if (props.onChange) {
      props.onChange(fileId)
    }

    const fileb = file as FileUpload
    await fileRepo.upload(fileId, fileb.file)

    setIsUploading(false)
  }

  const download = async (id: string) => {
    setIsUploading(true)
    await fileRepo.download(id)
    setIsUploading(false)

  }

  return (
    <Container highlight={props.highlight}>
      <TitleContainer>
        {props.title} 
        {props.required && <Required>*</Required>}
      </TitleContainer>

      <FileContainer>
      {isUploading && (<Spinner />)}
      {(!isUploading && !props.value && !props.disabled) && <DownloadButton onClick={() => selectFile({ accept: 'any', multiple: false }, upload)}>Upload</DownloadButton>}
      {(!isUploading && props.value) && <DownloadButton onClick={() => download(props.value || '')}>Download</DownloadButton>}
      {(!isUploading && props.value && !props.disabled) && <ReplaceButton onClick={() => selectFile({ accept: 'any', multiple: false }, upload)}>Replace</ReplaceButton>}
      </FileContainer>
    </Container>
  )
}