import moment from 'moment'
import { makeAutoObservable } from "mobx"
import { RawRequestedChange, requestedChangeRepo } from "../repositories/RequestedChangeRepo";

export type RequestedChangeStatus = 'pending' | 'approved' | 'denied'

export class RequestedChange {
  private id: string = ''
  private date: string = moment().toISOString()
  private userId: string = ''
  private recordId: string = ''
  private status: RequestedChangeStatus = 'pending'
  private reasonForDenial: string = ''
  private categoryId: string = ''

  private data: { [key: string]: string; } = {}

  constructor() {
    makeAutoObservable(this);
  }

  public getFieldValue(fieldId: string): string|null {
    return this.data[fieldId] || null
  }

  public setRecordId(recordId: string) {
    this.recordId = recordId
  }

  public getRecordId(): string {
    return this.recordId
  } 

  public setId(id: string) {
    this.id = id
  }

  public getId(): string {
    return this.id
  } 

  public setReasonForDenial(reasonForDenial: string) {
    this.reasonForDenial = reasonForDenial
  }

  public getReasonForDenial(): string {
    return this.reasonForDenial
  } 

  public setData(data: { [key: string]: string; }) {
    this.data = data
  }

  public getData() {
    return this.data
  }

  public setUserId(userId: string) {
    this.userId = userId
  }

  public getUserId(): string {
    return this.userId
  }

  public setDate(date: string) {
    this.date = date
  }

  public getDate(): string {
    return this.date
  }

  public setCategoryId(categoryId: string) {
    this.categoryId = categoryId
  }

  public getCategoryId(): string {
    return this.categoryId
  }

  public setStatus(status: RequestedChangeStatus) {
    this.status = status
  }

  public getStatus(): RequestedChangeStatus {
    return this.status
  }

  private async reset() {
    this.fromRawData(null)
  }

  public async load(categoryId: string, recordId: string, id: string) {
    this.reset()
    this.setId(id)
    const requestedChangeData = await requestedChangeRepo.getById(categoryId,recordId,id)
    if (requestedChangeData) {
      this.fromRawData(requestedChangeData)
    }
  }

  public fromRawData(data: RawRequestedChange | null) {
    const fieldData: { [key: string]: string } = {}
    for (const row of data?.data || []) {
      fieldData[row.fieldId] = row.value
    }
    this.setId(data?.id || '')
    this.setDate(data?.date || '')
    this.setData(fieldData)
    this.setUserId(data?.userId || '')
    this.setRecordId(data?.recordId || '')
    this.setStatus(data?.status || 'pending')
    this.setReasonForDenial(data?.reasonForDenial || '')
  }

  public async save() {
    const data = Object.entries(this.getData()).map(([key,value]) => ({
      fieldId: key,
      value
    }))
    await requestedChangeRepo.save(this.getCategoryId(), {
      id: this.getId(),
      date: this.getDate(),
      userId: this.getUserId(),
      recordId: this.getRecordId(),
      status: this.getStatus(),
      reasonForDenial: this.getReasonForDenial(),
      data,
    })
  }

  public approve(): void {
    this.status = 'approved'
    this.save()
  }

  public deny(reason: string): void {
    this.status = 'denied'
    this.setReasonForDenial(reason)
    this.save()
  }
}
