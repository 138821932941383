import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { Link } from '../components/Link'
import { DataRow } from '../components/DataRow'
import { DataCol } from '../components/DataCol'
import { useNavigate, useParams } from 'react-router-dom'
import { useFields } from '../hooks/useFields'
import { uuid } from '../helpers/UUID'
import { observer } from 'mobx-react'
import { DragHandle } from '@mui/icons-material';
import { theme } from '../theme'
import { ItemRenderProps, SortableItemProps, SortableList } from '@thaddeusjiang/react-sortable-list'
import { useEffect, useState } from 'react'
import '@thaddeusjiang/react-sortable-list/dist/index.css';
import { SpaceBetween } from '../components/SpaceBetween'
import { Box } from '../components/Box'
import { PreventDefault } from '../components/PreventDefault'
import { LoadingScreen } from '../components/LoadingScreen'

export const EditFieldsPage = observer(function () {
  const { editCategoryId } = useParams()
  const { fields, refetch, refetch: refetchFields, isLoading } = useFields(editCategoryId)
  const navigate = useNavigate()
  const [items, setItems] = useState<SortableItemProps[]>([]);
  const [isSorting, setIsSorting] = useState(false)

  useEffect(() => {
    const sortedFields = fields?.sort((a,b) => { return (a.getSortOrder() || 0) > (b.getSortOrder() || 0) ? 1 : -1 })
    setItems(sortedFields?.map((field) => ({ id: field.getId(), field })) || [])
  }, [fields])

  useEffect(() => {
    items.forEach(async (item, index) => {
      if (item.field.getSortOrder() !== index) {
        item.field.setSortOrder(index)
        await item.field.save()
      }
    })
  }, [items])

  const openField = (id: string) => {
    navigate(id)
  }

  return (
    <>
      <StackedChildren>
        <Popup>
          <ListContainer>

            {isSorting && (
              <SortableList
                items={items}
                setItems={setItems}
                itemRender={({ item }: ItemRenderProps) => (
                  <DataRow>
                    <SpaceBetween direction='horizontal'>
                      <DataCol>{item.field.getName()}</DataCol>
                      <Box paddingRightCode='sm'>
                        <DragHandle style={{ opacity: '0.5', cursor: 'grab' }} />
                      </Box>
                    </SpaceBetween>
                  </DataRow>
                )}
              />
            )}

            {!isSorting && (
              <>
                {items.map(item => (
                  <Link onClick={() => openField(item.field.getId())} key={`edit-field-${item.field.getId()}`}>
                    <DataRow>
                      <DataCol>{item.field.getName()}</DataCol>
                    </DataRow>
                  </Link>
                ))}
              </>
            )}
            
          </ListContainer>
          <SideBar>
            
            {!isSorting && <Button to={`${uuid.generate()}`}>Add Field</Button>}
            <Button onClick={() => setIsSorting(!isSorting)}>{isSorting ? 'Done' : 'Sort'}</Button>

          </SideBar>
        </Popup>
      </StackedChildren>
    </>
  )
})
