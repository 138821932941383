import { makeAutoObservable } from "mobx";
import { RawField, fieldRepo } from "../repositories/FieldRepo";

export type FieldType = 'text' | 'dropdown' | 'number' | 'checkbox' | 'date' | 'file'
export type ReservedFieldType = 'dateCreated' | 'dateChanged'

export class Field {
  private id: string = ''
  private name: string = ''
  private categoryId: string = ''
  private width?: number
  private showInList: boolean = true
  private required?: boolean
  private type: FieldType = 'text'
  private dropdownOptions: string[] = [];
  private reservedFieldType?: ReservedFieldType
  private sortOrder: number|null = null
  private deleted = false;

  public getDeleted(): boolean {
    return this.deleted
  }

  public setDeleted(deleted: boolean): void {
    this.deleted = deleted
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  public getAlignment(): 'left' | 'center' | 'right' {
    if (this.getType() === 'checkbox'){
      return 'center'
    } 
    if (this.getType() === 'number'){
      return 'right'
    } 
    return 'left'
  }

  public setRequired(required: boolean) {
    this.required = required
  }

  public formatValueByType(value: any) {
    if (this.getType() === 'number') {
      return value.replace(/[^0-9.-]/g, '');
    }
    return value
  }

  public getRequired() {
    return this.required
  }

  public setId(id: string) {
    this.id = id
  }

  public getId() {
    return this.id
  }

  public setSortOrder(sortOrder: number|null) {
    this.sortOrder = sortOrder
  }

  public getSortOrder() {
    return this.sortOrder
  }

  public setReservedType(type?: ReservedFieldType) {
    this.reservedFieldType = type
  }

  public getReservedType() {
    return this.reservedFieldType
  }

  public setShowInList(showInList: boolean) {
    this.showInList = showInList
  }

  public getShowInList() {
    return this.showInList
  }

  public setDropdownOptions(options: string[]) {
    this.dropdownOptions = options
  }

  public getDropdownOptions(): string[] {
    return this.dropdownOptions
  }

  private trimDropdownOptions() {
    this.dropdownOptions = this.dropdownOptions.map(option => option.trim())
  }

  public setType(type: FieldType) {
    this.type = type
  }

  public getType() {
    return this.type
  }

  public setName(name: string) {
    this.name = name
  }

  public getName() {
    return this.name
  }

  public setWidth(width?: number) {
    this.width = width
  }

  public getWidth() {
    return this.width
  }

  public setCategoryId(categoryId: string) {
    this.categoryId = categoryId
  }

  public getCategoryId() {
    return this.categoryId
  }

  private async reset() {
    this.fromRawData(null)
  }

  public async load(categoryId: string, id: string) {
    this.reset()
    this.setId(id)
    this.setCategoryId(categoryId)
    const user = await fieldRepo.getById(categoryId, id)
    if (user) {
      this.fromRawData(user)
    }
  }

  public fromRawData(data: RawField | null): void {
    this.setId(data?.id || '')
    this.setName(data?.name || '')
    this.setWidth(data?.width)
    this.setCategoryId(data?.categoryId || '')
    this.setRequired(data?.required || false)
    this.setType(data?.type || 'text' as FieldType)
    this.setReservedType(data?.reservedFieldType)
    this.setDropdownOptions(data?.dropdownOptions || [])
    this.setDeleted(data?.deleted || false)
    this.setSortOrder(data?.sortOrder || 99)
    if (typeof data?.showInList === 'boolean') {
      this.setShowInList(data.showInList)
    } else {
      this.setShowInList(true)
    }
  }

  public canSave(): boolean {
    if (!this.getName().trim()) return false
    return true
  }

  public async save() {
    if (!this.canSave()) return
    this.trimDropdownOptions()
    let rawData: RawField = {
      id: this.getId(),
      name: this.getName(),
      categoryId: this.getCategoryId(),
      width: this.getWidth(),
      required: this.getRequired(),
      type: this.getType(),
      dropdownOptions: this.getDropdownOptions(),
      showInList: this.getShowInList(),
      reservedFieldType: this.getReservedType(),
      sortOrder: this.getSortOrder(),
      deleted: this.getDeleted(),
    }
    await fieldRepo.save(rawData)
  }

 }