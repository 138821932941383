import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { ListTextInput } from '../components/ListTextInput'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserType } from '../models/UserType'
import { useUsersTypes } from '../hooks/useUsersTypes'
import { ListCheckbox } from '../components/ListCheckbox'
import { useCategories } from '../hooks/useCategories'
import { Text } from '../components/Text'
import { Box } from '../components/Box'
import { Permissions, injectCategoryId } from '../shared/Permissions'

const userType = new UserType()
const originalUserType = new UserType()

export const EditUserTypePage = observer(function () {
  const { userTypeId } = useParams()
  const navigate = useNavigate()
  const { refetch: refetchUserTypes } = useUsersTypes()
  const { categories } = useCategories()

  useEffect(() => {
    if (userTypeId) {
      userType.load(userTypeId)
      originalUserType.load(userTypeId)
    }
  }, [userTypeId])

  function hasChanges(): boolean {
    if (originalUserType.getName() !== userType.getName()) return true
    return false
  }

  const PermissionsCheckbox = observer(function (props: { permission: string, label: string }) {
    return (
      <ListCheckbox
        label={props.label}
        value={userType.hasPermission(props.permission)}
        onClick={(checked) => {
          if (checked) {
            userType.enablePermission(props.permission)
          } else {
            userType.disablePermission(props.permission)
          }
        }}
      />
    )
  })

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
          <ListTextInput 
            title="Name" 
            value={userType.getName()} 
            onChange={(newName) => userType.setName(newName)} 
          />

          <Box paddingCode="sm">
            <Text size="lg" weight='medium'>General Permissions</Text>
          </Box>

          <PermissionsCheckbox label="Settings" permission={Permissions.SETTINGS} />

          {categories?.map(category => (
            <>
              <Box paddingCode="sm">
                <Text size="lg" weight='medium'>{category.getName()} Permissions</Text>
              </Box>
              <PermissionsCheckbox label={`Access`} permission={injectCategoryId(Permissions.CATEGORIES_READ,category.getId())} />
              <PermissionsCheckbox label={`Approve / Deny`} permission={injectCategoryId(Permissions.CATEGORY_REVIEW,category.getId())} />
              <PermissionsCheckbox label={`Save without approval`} permission={injectCategoryId(Permissions.CATEGORY_SAVE_WITHOUT_APPROVAL,category.getId())} />
            </>
          ))}

        </ListContainer>
        <SideBar>
          
          <Button 
            disabled={!hasChanges() || !userType.canSave()}
            onClick={async () => {
              await userType.save()
              refetchUserTypes()
              navigate(-1)
            }}
          >
            Save
          </Button>

          <Button onClick={() => {
            userType.delete()
            refetchUserTypes()
            navigate(-1)
          }}>
            Delete
          </Button>
          
        </SideBar>
      </Popup>
    </StackedChildren>
  )
})
