import { dataProvider } from "../realtimeDatabase";
import { Filter } from "../models/Filter";
import axios from "axios";
import { config } from "../config";

export interface RawRecord { 
  id: string
  categoryId: string
  signedOutBy?: string | null
  hasChangeRequests?: boolean
  pendingNewDocumentApproval?: boolean
  isArchived?: boolean
  deleted: boolean
  data: {
    fieldId: string
    value: string
  }[]
}

class RecordRepo {

  public  async getById(categoryId: string, id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawRecord
      return resource
    } catch(e) {
      console.log('Failed to get record by id',e)
    }
    return null
  }

  public async getByCategoryId(categoryId: string, params?: { [key: string]: string }): Promise<RawRecord[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records`, { params, headers: { Authorization: `Bearer ${token}` }})
      const resources = response.data.resources as RawRecord[]
      return resources
    } catch(e) {
      console.log('Failed to get records',e)
    }
    return []
  }

  public  async save(categoryId: string, data: RawRecord) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/categories/${categoryId}/records/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawRecord
      return resource
    } catch(e) {
      console.log('Failed to save record',e)
    }
    return null
  }

}

export const recordRepo = new RecordRepo()
