import axios from "axios";
import { dataProvider } from "../realtimeDatabase";
import { config } from "../config";

export interface RawSftp { 
  username: string;
  password?: string;
  port: string;
  host: string;
}

class SftpRepo {

  public  async get() {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/sftp`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawSftp
      return resource
    } catch(e) {
      console.log('Failed to get sftp',e)
    }
    return null
  }

  public async save(data: RawSftp) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/sftp`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawSftp
      return resource
    } catch(e) {
      console.log('Failed to save sftp',e)
    }
    return null
  }

}

export const sftpRepo = new SftpRepo()
