import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  width: 300px;
  height: 100%;
  background-color: #1b1f27;
  overflow-y: auto;
`

// #1b1f27 #14151c

export function SideBar(props: Props) {
  return (
    <Container>
      {props.children}
    </Container>
  )
}