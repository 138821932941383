import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  onClose?: () => void;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
  width: 600px;
  overflow: hidden;
  border-radius: 15px;
`

export function Popup(props: Props) {
  return (
    <Container onClick={() => props.onClose && props.onClose()}>
      <Content onClick={(e) => e.stopPropagation()}>
        {props.children}
      </Content>
    </Container>
  )
}