import { v4 as uuidv4 } from 'uuid';

export class UUID {

  public generate(): string {
    return uuidv4();
  }

}

export const uuid = new UUID();