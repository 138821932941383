import { uuid } from "../helpers/UUID";
import moment from 'moment'
import { makeAutoObservable } from "mobx"
import { RequestedChange } from "./RequestedChange";
import { RawVersion, versionRepo } from "../repositories/VersionRepo";
import { Record } from "./Record";

export class Version {
  private id: string = ''
  private date: string = moment().toISOString()
  private data: any = {}
  private userId: string = ''
  private approverId: string | null = null
  private recordId: string = ''
  private categoryId: string = ''

  constructor() {
    makeAutoObservable(this);
  }

  public getCategoryId(): string {
    return this.categoryId
  }

  public setCategoryId(categoryId: string): void {
    this.categoryId = categoryId
  }

  public getFieldValue(fieldId: string): string|null {
    return this.data[fieldId] || null
  }

  public setData(data: object) {
    this.data = data
  }

  public getData(): object {
    return this.data
  }

  public setId(id: string) {
    this.id = id
  }

  public getId(): string {
    return this.id
  }

  public setRecordId(recordId: string) {
    this.recordId = recordId
  }

  public getRecordId(): string {
    return this.recordId
  }

  public setUserId(userId: string) {
    this.userId = userId
  }

  public getUserId(): string {
    return this.userId
  }

  public setApproverId(approverId?: string | null) {
    this.approverId = approverId || null
  }

  public getApproverId(): string | null {
    return this.approverId
  }

  public setDate(date: string) {
    this.date = date
  }

  public getDate(): string {
    return this.date
  }

  private async reset() {
    this.fromRawData(null)
  }

  public async load(categoryId: string, recordId: string, id: string) {
    this.reset()
    this.setId(id)
    this.setCategoryId(categoryId)
    const versionData = await versionRepo.getById(categoryId, recordId, id)
    if (versionData) {
      this.fromRawData(versionData)
    }
  }

  public fromRawData(data: RawVersion | null) {
    const fieldData: { [key: string]: string } = {}
    for (const row of data?.data || []) {
      fieldData[row.fieldId] = row.value
    }
    this.setId(data?.id || '')
    this.setDate(data?.date || '')
    this.setData(fieldData)
    this.setUserId(data?.userId || '')
    this.setRecordId(data?.recordId || '')
    this.setApproverId(data?.approverId || null)
  }

  public fromRecord(record: Record) {
    this.setId(uuid.generate())
    this.setCategoryId(record.getCategoryId())
    this.setDate(moment().toISOString())
    this.setData(record.getData())
    this.setRecordId(record.getId())
  }  

  public async save() {
    const data = Object.entries(this.getData()).map(([key,value]) => ({
      fieldId: key,
      value
    }))
    await versionRepo.save(this.getCategoryId(), {
      id: this.getId(),
      date: this.getDate(),
      userId: this.getUserId(),
      approverId: this.getApproverId(),
      recordId: this.getRecordId(),
      data,
    })
  }

}
