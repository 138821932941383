import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { ListTextInput } from '../components/ListTextInput'
import { Link } from '../components/Link'
import { ListCheckbox } from '../components/ListCheckbox'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Category } from '../models/Category'
import { observer } from 'mobx-react'
import { useCategories } from '../hooks/useCategories'
import { useUsersTypes } from '../hooks/useUsersTypes'

const originalCategory = new Category();
const category = new Category();

export const EditCategoryPage = observer(function () {
  const { editCategoryId } = useParams()
  const [saving, setSaving] = useState(false)
  const { refetch: refetchCategories } = useCategories()
  const { refetch: refetchPermissions } = useUsersTypes()

  useEffect(() => {
    if (editCategoryId) {
      originalCategory.load(editCategoryId)
      category.load(editCategoryId)
    }
  }, [editCategoryId])

  function hasChanges(): boolean {
    if (originalCategory.getName() !== category.getName()) return true
    if (originalCategory.getRequireApproval() !== category.getRequireApproval()) return true
    if (originalCategory.getSignInOut() !== category.getSignInOut()) return true
    return false
  }

  async function saveCategory(): Promise<void> {
    if (!editCategoryId || !category || saving) {
      return
    }
    setSaving(true)
    await category.save()
    originalCategory.load(editCategoryId)
    refetchCategories()
    refetchPermissions()
    setSaving(false)
  }

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>
          
          <ListTextInput title="Name" value={category?.getName() || ''} onChange={(newName) => category?.setName(newName)} />
          <ListCheckbox label='Require change approval' value={category?.getRequireApproval()} onClick={(newValue) => category?.setRequireApproval(newValue)} />
          <ListCheckbox label='Enable sign in / out' value={category?.getSignInOut()} onClick={(newValue) => category?.setSignInOut(newValue)} />

        </ListContainer>
        <SideBar>
          
          <Button 
            disabled={!hasChanges() || !category.canSave()} 
            onClick={() => saveCategory()}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Link to='fields'>
            <Button>Fields</Button>
          </Link>
          <Button onClick={async () => {
            await category.setDeleted(true)
            await category.save()
            await refetchCategories()
          }}>Delete</Button>

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})
