import { useQuery } from 'react-query';
import { Category } from '../models/Category';
import { categoryRepo } from '../repositories/CategoryRepo';
import { uuid } from '../helpers/UUID';
import { toJS } from 'mobx';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useCategories = () => {
  const { 
    isLoading, 
    data: categories,
    refetch,  
  } = useQuery(['categories'], async () => {
    const categoryData = await categoryRepo.getAll()
    const categories = categoryData.map(row => {
      const category = new Category();
      category.fromRawData(row) 
      return category
    })

    const nonDeletedCategories = categories.filter(category => !category.getDeleted())

    return nonDeletedCategories
  }, options)

  return {
    categories,
    refetch,
    isLoading,
  }
}