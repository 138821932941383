import { useQuery } from 'react-query';
import { User } from '../models/User';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useUser = (id?: string) => {
  const { 
    isLoading, 
    data: user,
    refetch,  
  } = useQuery(['user', id], async () => {
    if (!id) return null 

    const user = new User()
    await user.load(id)

    if (user.getDeleted()) {
      user.setName(`${user.getName()} (deleted)`)
    }
    
    return user
  }, options)

  return {
    user,
    refetch,
    isLoading,
  }
}