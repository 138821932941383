import { StackedChildren } from '../components/StackedChildren'
import { SideBar } from '../components/SideBar'
import { ListContainer } from '../components/ListContainer'
import { Popup } from '../components/Popup'
import { Button } from '../components/Button'
import { Link } from "../components/Link";
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { ListDropdown } from '../components/ListDropdown'
import { ListTextInput } from '../components/ListTextInput'
import { Sftp } from '../models/Sftp'
import { useSftp } from '../hooks/useSftp'
import { useEffect } from 'react'

const sftp = new Sftp()
const originalSftp = new Sftp()

export const StoragePane = observer(function () {
  const { refetch } = useSftp()
  const navigate = useNavigate()

  useEffect(() => {
    sftp.load()
    originalSftp.load()
  }, [])

  function hasChanges(): boolean {
    if (originalSftp.getUsername() !== sftp.getUsername()) return true
    if (originalSftp.getPassword() !== sftp.getPassword()) return true
    if (originalSftp.getHost() !== sftp.getHost()) return true
    if (originalSftp.getPort() !== sftp.getPort()) return true
    return false
  }

  return (
    <StackedChildren>
      <Popup>
        <ListContainer>

          <ListDropdown
            label='Type'
            value={'SFTP'}
            options={[
              { label: 'SFTP', value: 'SFTP' }
            ]}
            onChange={() => {}}
          />

          <ListTextInput 
            title='Host' 
            value={sftp.getHost()} 
            onChange={(newValue) => sftp.setHost(newValue)} 
            required
          />
          <ListTextInput 
            title='Port' 
            value={sftp.getPort()} 
            onChange={(newValue) => sftp.setPort(newValue)} 
            required
          />
          <ListTextInput 
            title='Username' 
            value={sftp.getUsername()} 
            onChange={(newValue) => sftp.setUsername(newValue)} 
            required
          />
          <ListTextInput 
            title='Password' 
            value={sftp.getPassword()} 
            onChange={(newValue) => sftp.setPassword(newValue)} 
            required  
          />

        </ListContainer>
        <SideBar>
          
          <Link>
            <Button
              disabled={!hasChanges() || !sftp.canSave()}
              onClick={async () => {
                await sftp.save()
                refetch()
                navigate(-1)
              }}
            >
              Save
            </Button>
          </Link>

        </SideBar>
      </Popup>
    </StackedChildren>
  )
})