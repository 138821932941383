import { makeAutoObservable } from "mobx"
import { RawRecord, recordRepo } from "../repositories/RecordRepo";
import { Record } from "./Record";
import { Filter } from "./Filter";

export class RecordSearch {
  private results: Record[] = []
  private allResults: Record[] = []
  private showBookmarks = false
  private showArchived = false
  private showRequestedChanges = false
  private categoryId = ''
  private searchText: string = ''
  private sortDirection: 'asc' | 'desc' = 'asc'
  private sortFieldId: string | null = null
  private timeout = setTimeout(() => {}, 500)
  private hasLoadedFirstTime = false;
  
  constructor() {
    makeAutoObservable(this);
  }

  public hasLoaded(): boolean {
    return this.hasLoadedFirstTime
  }

  private setHasLoaded() {
    this.hasLoadedFirstTime = true
  }

  public setSearchText(text: string) {
    this.searchText = text.toLocaleLowerCase()
    this.search()
  }

  public getSearchText() {
    return this.searchText
  }

  private async getAll(): Promise<Record[]> {
    if (!this.getCategoryId()) {
      return []
    }
    const filters: { [key: string]: any } = {}

    if (this.getShowArchived()) {
      filters.showArchived = true
    }

    if (this.getShowBookmarks()) {
      filters.bookmarksOnly = true
    }

    if (this.getShowRequestedChanges()) {
      filters.requestedChangesOnly = true
    }

    if (this.getSearchText()) {
      filters.searchText = this.getSearchText()
    }

    const recordData = await recordRepo.getByCategoryId(this.getCategoryId(), filters)
    const records = this.fromRawData(recordData)

    return records
  }

  private fromRawData(data: RawRecord[]) {
    const records = data.map(row => {
      const record = new Record();
      record.fromRawData(row) 
      return record
    })
    return records
  }

  private async actuallySearch() {
    this.allResults = await this.getAll()
    this.results = this.allResults
    this.setHasLoaded()
  }

  public async search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => { this.actuallySearch() }, 500)
  }

  public getShowBookmarks() {
    return this.showBookmarks
  }

  public setShowBookmarks(showBookmarks: boolean) {
    this.showBookmarks = showBookmarks
    this.search()
  }

  public getShowArchived() {
    return this.showArchived
  }

  public setShowArchived(showArchived: boolean) {
    this.showArchived = showArchived
    this.search()
  }

  public getShowRequestedChanges() {
    return this.showRequestedChanges
  }

  public setShowRequestedChanges(showRequestedChanges: boolean) {
    this.showRequestedChanges = showRequestedChanges
    this.search()
  }

  private reset() {
    this.results = []
    this.allResults = []
    this.search()
  }

  public setCategoryId(categoryId: string) {
    this.categoryId = categoryId
    this.reset()
  }

  public getCategoryId() {
    return this.categoryId
  }

  public getResults() {
    return this.results
  }

  public getSortField() {
    return this.sortFieldId
  }

  public setSortField(fieldId: string | null) {
    if (fieldId && fieldId === this.sortFieldId) {
      if (this.getSortDirection() === 'asc') {
        this.setSortDirection('desc')
      } else {
        this.setSortField(null)
      }
      this.search()
      return;
    }
    this.sortFieldId = fieldId
    this.setSortDirection('asc')
    this.search()
  }

  public getSortDirection() {
    return this.sortDirection
  }

  private setSortDirection(direction: 'asc' | 'desc') {
    this.sortDirection = direction
    this.search()
  }
  
}