import { Button } from '../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useData } from '../../hooks/useData';

export const BackButton = observer(function () {
  const navigate = useNavigate();
  const { recordId, categoryId } = useParams()
  const { data } = useData(categoryId, recordId)

  return (
    <Button onClick={() => {
      navigate(-1)
      data?.clearEdits()
    }}>
      Back
    </Button>
  )
})
