import styled from "styled-components";
import { Logout as SettingsMaterialIcon } from '@mui/icons-material';
import { theme } from "../theme";
import { Text } from "./Text";
import { Link } from "./Link";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";

const Icon = styled(SettingsMaterialIcon)`
  font-size: 20px !important;
  color: white;  
  margin-right: ${theme.spacing.xxxs};
`

const Container = styled.div`
  padding: ${theme.spacing.md};
  align-items: center;
  opacity: 0.5;
  display: flex;
  transition: all 0.25s !important;
  cursor: pointer;
  justify-content: space-between;
  &:hover {
    opacity: 1;
  }
`

const OutterContainer = styled.div`
  border-top: 1px solid rgba(255,255,255,0.1);
  width: 100%;
`

export function LogoutButton() {
  const navigate = useNavigate()

  async function logout() {
    await firebase.auth().signOut();
    navigate('/')
  }

  return (
      <OutterContainer>
        <Link onClick={logout}>
          <Container>
            <Text size="md" color="white" weight="medium">Logout</Text>
            <Icon />
          </Container>
        </Link>
      </OutterContainer>
  )
}