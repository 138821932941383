import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { useData } from '../../hooks/useData'
import { useMe } from '../../hooks/useMe'
import { observer } from "mobx-react";

export const BookmarkButton = observer(function () {
  const { recordId, categoryId } = useParams()
  const { me } = useMe()
  const { data } = useData(categoryId, recordId)

  if (!data || !me) {
    return <></>
  }

  if (data.isNew()) {
    return <></>
  }

  return (
    <Button onClick={() => me.bookmark(data.getId())}>
      {me.isBookmarked(data.getId()) ? 'Bookmarked' : 'Bookmark'}
    </Button> 
  )
})
