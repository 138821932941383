import { makeAutoObservable } from "mobx";
import { RawField, fieldRepo } from "../repositories/FieldRepo";
import { RawSftp, sftpRepo } from "../repositories/SftpRepo";

export class Sftp {
  private username: string = ''
  private password: string = ''
  private port: string = ''
  private host: string = ''

  constructor() {
    makeAutoObservable(this);
  }

  public setUsername(username: string) { this.username = username }
  public getUsername() { return this.username }

  public setPassword(password: string) { this.password = password }
  public getPassword() { return this.password }

  public setHost(host: string) { this.host = host }
  public getHost() { return this.host }

  public setPort(port: string) { this.port = port }
  public getPort() { return this.port }

  private reset = () => this.fromRawData(null)

  public async load() {
    this.reset()
    const response = await sftpRepo.get()
    if (response) {
      this.fromRawData(response)
    }
  }

  public fromRawData(data: RawSftp | null): void {
    this.setUsername(data?.username || '')
    this.setPassword(data?.password || '')
    this.setHost(data?.host || '')
    this.setPort(data?.port || '')
  }

  public canSave(): boolean {
    if (!this.getPassword().trim()) return false
    if (!this.getUsername().trim()) return false
    if (!this.getPassword().trim()) return false
    if (!this.getPort().trim()) return false
    return true
  }

  public async save() {
    if (!this.canSave()) return
    let rawData: RawSftp = {
      username: this.getUsername(),
      password: this.getPassword(),
      host: this.getHost(),
      port: this.getPort(),
    }
    await sftpRepo.save(rawData)
  }

 }