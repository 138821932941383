import axios from "axios";
import { config } from "../config";
import { RequestedChangeStatus } from "../models/RequestedChange";
import { dataProvider } from "../realtimeDatabase";

export interface RawRequestedChange { 
  id: string
  date: string 
  userId: string
  recordId: string
  status: RequestedChangeStatus
  reasonForDenial: string
  data: {
    fieldId: string
    value: string
  }[]
}

class RequestedChangeRepo {

  public  async getById(categoryId: string, recordId: string, id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records/${recordId}/requested-changes/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawRequestedChange
      return resource
    } catch(e) {
      console.log('Failed to get field by id',e)
    }
    return null
  }

  public async getByRecordId(categoryId: string, recordId: string): Promise<RawRequestedChange[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/records/${recordId}/requested-changes`, { headers: { Authorization: `Bearer ${token}` }})
      const resources = response.data.resources as RawRequestedChange[]
      return resources
    } catch(e) {
      console.log('Failed to get fields',e)
    }
    return []
  }

  public  async save(categoryId: string, data: RawRequestedChange) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/categories/${categoryId}/records/${data.recordId}/requested-changes/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawRequestedChange
      return resource
    } catch(e) {
      console.log('Failed to save field',e)
    }
    return null
  }
}

export const requestedChangeRepo = new RequestedChangeRepo()
