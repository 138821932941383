interface Config {
  backend: {
    baseurl: string
  },
  providers: {
    firebase: {
      apiKey: string
      authDomain: string
      projectId: string
      storageBucket: string
      messagingSenderId:string
      appId: string
      measurementId?:string
    },
  },
}

export const config: Config = {
  backend: {
    baseurl: window.location.origin.includes('localhost') ? 'http://localhost:8080' : 'https://api.recordrooster.com',
  },
  providers: {
    firebase: {
      apiKey: "AIzaSyD2Re94KI0dHz73umTVp-exixpzSY1VTN8",
      authDomain: "document-control-60773.firebaseapp.com",
      projectId: "document-control-60773",
      storageBucket: "document-control-60773.appspot.com",
      messagingSenderId: "1074381774589",
      appId: "1:1074381774589:web:7a4fe011279bdd24e9e894",
      measurementId: "G-1DZMDXP37Q"
    },
  },
}