import { useQuery } from 'react-query';
import { RequestedChange } from '../models/RequestedChange';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useRequestedChange = (categoryId?: string, recordId?: string, requestedChangeId?: string) => {
  const { 
    isLoading, 
    data: requestedChange,
    refetch,  
  } = useQuery(['requestedChange', requestedChangeId, recordId, categoryId], async () => {
    if (!requestedChangeId || !recordId || !categoryId) return null

    const requestedChange = new RequestedChange();
    await requestedChange.load(categoryId, recordId, requestedChangeId)

    return requestedChange
  }, options)

  return {
    requestedChange,
    refetch,
    isLoading,
  }
}