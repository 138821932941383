import { Button } from '../../components/Button'
import { useCategory } from '../../hooks/useCategory'
import { useParams } from 'react-router-dom'
import { useData } from '../../hooks/useData';
import { useMe } from '../../hooks/useMe';
import { observer } from 'mobx-react';

export const SignOutButton = observer(function () {
  const { categoryId, recordId } = useParams()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)
  const { me } = useMe()

  if (!category || !data || !me) {
    return <></>;
  }

  if (!category.getSignInOut()) {
    return <></>;
  }

  if (!data.canSignOut()) {
    return <></>;
  }

  if (data.isNew()) {
    return <></>
  }

  return (
    <Button onClick={() => data.signOut(me.getId())}>Sign Out</Button>
  )
})
