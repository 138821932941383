import styled from "styled-components";
import { keyframes } from 'styled-components'

const animateInOpacity = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-name: ${animateInOpacity};
  animation-iteration-count: infinite;
  opacity: 0.7;
`

export function Spinner() {
  return (
    <Container>
      <img src={require('../assets/loadingspinner.png')} style={{ width: 40 }} />
    </Container>
  )
}