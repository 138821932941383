import styled from "styled-components";
import { theme } from "../theme";
import { KeyboardArrowDown } from '@mui/icons-material';
import { validate as isValidUUID } from 'uuid';

interface Option<T>{
  label: string;
  value: T;
}

interface Props<T> {
  label?: string;
  options: Option<T>[];
  value: Option<T>['value'];
  onChange: (newValue: T) => void;
  id?: string;
  required?: boolean;
  highlight?: boolean;
  disabled?: boolean;
}

const Container = styled.div<{ highlight?: boolean; disabled?: boolean; }>`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  flex-direction: column;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  align-items: left;
  cursor: pointer;
  position: relative;
  ${props => props.highlight ? 'background-color: #E8F4FE;' : ''}
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  pointer: ${props => props.disabled ? 'default' : 'pointer'};
`

const TitleContainer = styled.div`
  font-size: ${theme.typography.textSizes.sm};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xs};
  padding-top: ${theme.spacing.xxs};
`


const Dropdown = styled.select`
  font-size: ${theme.typography.textSizes.md};
  justify-content: center;
  cursor: pointer;
  padding-left: ${theme.spacing.xs};
  padding-top: ${theme.spacing.xxs};
  padding-bottom: ${theme.spacing.xs};
  padding-right: ${theme.spacing.xxl};
  border: none;
  outline: none;
  width: 100%;
`

const DownArrowContainer = styled.div`
  pointer-events: none;
  position: absolute;
  right: ${theme.spacing.sm};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
`

const Required = styled.div`
  color: red;
  padding-left: ${theme.spacing.xxxs};
`

const Arrow = styled(KeyboardArrowDown)<{ disabled?: boolean; }>`
  color: black !important;
  opacity: ${props => props.disabled ? 0.3 : 1};
`

export function ListDropdown<T>(props: Props<T>) {
  const sanitizedId = isValidUUID(props.id || '') ? props.id : undefined
  return (
    <Container highlight={props.highlight} disabled={props.disabled}>
      {props.label && (
        <TitleContainer>
          {props.label}
          {props.required && <Required>*</Required>}
        </TitleContainer>
      )}
      <div style={{ position: 'relative' }}>
        <Dropdown id={sanitizedId} disabled={props.disabled} value={props.value as any} onChange={(event) => props.onChange(event.target.value as T)}>
          {props.options.map(option => (
            <option key={'option-value-'+option.value} value={option.value as string} style={{ fontWeight: 'normal' }}>{option.label}</option>
          ))}
        </Dropdown>
        <DownArrowContainer>
          <Arrow disabled={props.disabled} />
        </DownArrowContainer>
      </div>
    </Container>
  )
}