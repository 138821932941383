import styled from "styled-components";
import { theme } from "../theme";
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';

interface Props {
  label: string;
  onClick?: (checked: boolean) => void;
  value?: boolean;
}

const Container = styled.div`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  flex-direction: row;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: ${theme.spacing.xxs};
  padding-left: ${theme.spacing.sm};
  align-items: center;
  cursor: pointer
`

const TitleContainer = styled.div`
  font-size: ${theme.typography.textSizes.sm};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xs};
`

const Checked = styled(CheckBox)`
  font-size: 15px;
  margin-left: -5px;
`

const UnChecked = styled(CheckBoxOutlineBlankOutlined)`
  font-size: 15px;
  margin-left: -5px;
`

export function ListCheckbox(props: Props) {
  return (
    <Container onClick={() => props.onClick && props.onClick(!props.value)} >
      {props.value && <Checked />}
      {!props.value && <UnChecked />}
      <TitleContainer>{props.label}</TitleContainer>
    </Container>
  )
}