import { Button } from '../../components/Button'
import { useCategory } from '../../hooks/useCategory'
import { useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../hooks/useData';
import { useMe } from '../../hooks/useMe';
import { observer } from 'mobx-react';
import { useRequestedChange } from '../../hooks/useRequestedChange';
import { Version } from '../../models/Version';
import { useRequestedChanges } from '../../hooks/useRequestedChanges';
import { Record } from '../../models/Record';
import { useRecordSearch } from '../../hooks/useRecordSearch';
import { useVersions } from '../../hooks/useVersions';

export const ApproveButton = observer(function () {
  const { categoryId, recordId, requestedChangeId } = useParams()
  const { category } = useCategory(categoryId)
  const { data, refetch: refetchData } = useData(categoryId, recordId)
  const { versions, refetch: refetchVersions } = useVersions(categoryId, recordId)
  const { me } = useMe()
  const navigate = useNavigate()
  const { requestedChange } = useRequestedChange(categoryId, recordId, requestedChangeId)
  const { refetch: refetchRequestedChanges, requestedChanges } = useRequestedChanges(recordId)
  const { recordSearch } = useRecordSearch()

  if (!category || !data || !me || !requestedChangeId || !requestedChange || !recordId || !categoryId) {
    return <></>;
  }

  if (data.isNew()) {
    return <></>
  }

  return (
    <Button onClick={async () => {
      requestedChange.setStatus('approved')
      await requestedChange.save()
      
      refetchRequestedChanges()
      recordSearch?.search()
      refetchVersions()

      navigate(-1)
    }}>Approve</Button>
  )
})
