import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`

export function ListContainer(props: Props) {
  return (
    <Container>
      {props.children}
    </Container>
  )
}