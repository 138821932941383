import { config } from "../config";
import { Filter } from "../models/Filter";
import { FirestoreProvider } from "./providers/firestore";

export interface DataProvider { 
  getAll<T>(collection: string): Promise<T[]>
  getById: <T>(collection: string, id: string) => Promise<T>;
  set: (collection: string, id: string, data: any) => void;
  delete: (collection: string, id: string) => void;
  getByField<T>(collection: string, field: string, value: string, filters?: Filter[]): Promise<T[]>;
  getToken(): Promise<string | null>;
  getUserId(): Promise<string | null>;
  getApp(): Promise<any>;
}

function factory($provider: 'firestore') {
  switch($provider) {

    /**
     * Firestore has usage costs but may be the only
     * option in the future
     */
    case 'firestore':
      return new FirestoreProvider(config.providers.firebase!);

  }
}

export const dataProvider: DataProvider = factory('firestore');
