import { makeAutoObservable } from "mobx"
import { RawUser, userRepo } from "../repositories/UserRepo";

export class User {
  private id: string = ''
  private bookmarks: string[] = [];
  public name: string = ''
  public type: string = ''
  public email: string = ''
  private deleted = false;

  public getDeleted(): boolean {
    return this.deleted
  }

  public setDeleted(deleted: boolean): void {
    this.deleted = deleted
  }
  
  constructor() {
    makeAutoObservable(this);
  }

  public bookmark(recordId: string): void {
    if (this.isBookmarked(recordId)) {
      this.removeBookmark(recordId)
    } else {
      this.addBookmark(recordId)
    }
    this.save()
  }

  private removeBookmark(recordId: string) {
    const index = this.bookmarks.indexOf(recordId)
    this.bookmarks.splice(index, 1)
  }

  private addBookmark(recordId: string) {
    this.bookmarks.push(recordId)
  }

  public isBookmarked(recordId: string): boolean {
    return this.bookmarks.indexOf(recordId) >= 0
  }

  public getId = () => this.id
  public setId = (id: string) => this.id = id

  public getType = () => this.type
  public setType = (type: string) => this.type = type

  public getEmail = () => this.email
  public setEmail = (email: string) => this.email = email

  public setName = (name: string) => this.name = name
  public getName = () => this.name

  public getBookmarks = () => this.bookmarks
  public setBookmarks = (bookmarks: string[]) => this.bookmarks = bookmarks

  private reset = () => this.fromRawData(null)

  public async load(id: string) {
    this.reset()
    this.setId(id)
    const user = await userRepo.getById(id)
    if (user) {
      this.fromRawData(user)
    }
  }

  public fromRawData(user: RawUser | null) {
    this.setId(user?.id || '')
    this.setName(user?.name || '')
    this.setBookmarks(user?.bookmarks || [])
    this.setType(user?.type || '')
    this.setEmail(user?.email || '')
    this.setDeleted(user?.deleted || false)
  }

  public canSave() {
    if (!this.getName().trim()) return false
    if (!this.getType()) return false
    return true
  }

  public async save() {
    if (!this.canSave()) return
    await userRepo.save({
      id: this.getId(),
      name: this.getName(),
      bookmarks: this.getBookmarks(),
      type: this.getType(),
      email: this.getEmail(),
      deleted: this.getDeleted(),
    })
  }

  public async delete() {
    this.setDeleted(true)
  }

}
