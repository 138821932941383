import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { DataProvider } from '..';
import { Filter } from '../../models/Filter';

export class FirestoreProvider implements DataProvider {

  private app?: firebase.app.App;
  private config = {}

  constructor(config: object) {
    this.config = config
  }

  public async getApp() {
    if (this.app) {
      return this.app
    }
    this.app = await firebase.initializeApp(this.config);
    firebase.firestore().settings({
      ignoreUndefinedProperties: true,
    })
    return this.app
  }

  public async getAll<T>(collection: string): Promise<T[]> {
    await this.getApp()
    const snapshot = await firebase.firestore().collection(collection).get()
    return snapshot.docs.map(doc => doc.data()) as T[];
  }

  public async getById<T>(collection: string, id: string): Promise<T> {
    await this.getApp()
    const docRef = await firebase.firestore(this.app).collection(collection).doc(id).get()
    const result = docRef.data()
    return result as T
  }

  public async getByField<T>(collection: string, field: string, value: string, filters?: Filter[]): Promise<T[]> {
    await this.getApp()

    let docRef = firebase.firestore(this.app).collection(collection)
    let query = docRef.where(field, 'in', [value])

    filters?.forEach((filter) => {
      if (filter.type === 'in') {
        query = docRef.where(filter.field, 'in', filter.value)
      }
    })
    
    const results = await query.get()
    return results.docs.map(doc => doc.data()) as T[];
  }

  public async set(collection: string, id: string, data: any) {
    await this.getApp()

    const document = firebase.firestore(this.app).collection(collection).doc(id);
    document.set(data);
  }

  public async delete(collection: string, id: string) {
    await this.getApp()

    const docRef = firebase.firestore(this.app).collection(collection).doc(id);
    docRef.delete();
  }

  private hasLoggedIn = false;
  private async waitForLogin() {
    return new Promise((resolve) => {
      if (this.hasLoggedIn) {
        resolve(true)
      }
      let waitForCurrentUser = setInterval(() => {
        if ( firebase.auth().currentUser?.uid) {
            this.hasLoggedIn = true
            clearInterval(waitForCurrentUser);
            resolve(true)
        }
      }, 50);
    })
  }
  
  public async getToken(): Promise<string | null> {
    await this.getApp()
    await this.waitForLogin()
    const token = await firebase.auth().currentUser?.getIdToken()
    const currentUser = await firebase.auth().currentUser
    console.log({ currentUser, token })
    return token || null 
  }

  public async getUserId(): Promise<string | null> {
    await this.getApp()
    await this.waitForLogin()
    const currentUser = await firebase.auth().currentUser
    return currentUser?.uid || null 
  }

}