import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../theme";
import { Box } from "../components/Box";
import { Text } from "../components/Text";
import { Link } from "../components/Link";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Google as GoogleIcon, Apple as AppleIcon } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { dataProvider } from "../realtimeDatabase";
import { userRepo } from "../repositories/UserRepo";

dataProvider.getApp()

const MainSection = styled.div`
  font-size: ${theme.typography.textSizes.xl};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

const SignUpButton = styled.div`
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
  font-size: ${theme.typography.textSizes.md};
  border-radius: 20px;
  background-color: ${theme.palette.accent};
  color: white;
  text-align: center;
  width: 170px;
  cursor: pointer;
`

const LoginText = styled.input`
  display: catet !important;
  padding: ${theme.spacing.sm} ${theme.spacing.lg} !important;
  font-size: ${theme.typography.textSizes.lg} !important;
  border-radius: 20px !important;
  background-color: rgba(0,0,0,0.05) !important;
  color: black !important;
  width: 350px !important;
`

export const SignIn = observer(function () {
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const inviteToken = searchParams.get('token')

  async function loginWithGoogle() {
    let provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  }

  async function signInWithEmail() {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch(error: any) {
      alert(error)
    }
  }

  async function signUpWithEmail() {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch(error: any) {
      alert(error)
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const user = await userRepo.signUp(firebaseUser.uid, inviteToken)
          navigate('/dashboard')
        } catch(error) {
          alert('Failed to sign up '+error)
        }
      }
   });
  }, [firebase])

  return <>
    <MainSection>

      <Link to="/">
        <img src={require('../assets/logo7@3x.png')} width={70} alt='logo' />
      </Link>

      <Box paddingTopCode="xxl" direction="vertical">
        <LoginText value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Email Address" />
      </Box>

      <Box paddingTopCode="sm" direction="vertical">
        <LoginText value={password} onChange={(event) => setPassword(event.target.value)} type="password" placeholder="Password" />
      </Box>

      <Box paddingTopCode="sm" direction="horizontal">
        <Box paddingRightCode="xxs">
          <SignUpButton onClick={signUpWithEmail}>Sign Up</SignUpButton>
        </Box>
        <SignUpButton onClick={signInWithEmail}>Sign In</SignUpButton>
      </Box>

      <Box paddingTopCode="md" direction="horizontal" alignHorizontal="center" alignVertical="center">
        <Text weight="medium" size="sm" color="secondary">
          By signing in, you agree to the 
          <Link to="/terms">Terms of Service</Link>
        </Text>
      </Box>

      <Box paddingTopCode="xxl" direction="vertical" alignHorizontal="center" alignVertical="center">
        <Text weight="medium" size="md" color="secondary">Sign in with</Text>

        <Box paddingTopCode="xs">
          <Link onClick={loginWithGoogle}>
            <GoogleIcon fontSize="large" style={{ color: '#293d6b', cursor: 'pointer' }} />
          </Link>
        </Box>
      </Box>

      

    </MainSection>
  </>
})
