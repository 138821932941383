import styled from "styled-components";
import { theme } from "../theme";
import { validate as isValidUUID } from 'uuid';
import moment from "moment";

interface Props {
  id?: string;
  title: string;
  value?: string;
  onChange?: (newValue: string) => void;
  highlight?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
}

const Container = styled.div<{ highlight?: boolean; }>`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  flex-direction: column;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  transition: all 0.3s;
  ${props => props.highlight ? 'background-color: #E8F4FE;' : ''}
`

const TitleContainer = styled.div`
  font-size: ${theme.typography.textSizes.sm};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xxs};
  padding-top: ${theme.spacing.xxs};
`

const TextInput = styled.input`
  font-size: ${theme.typography.textSizes.md};
  display: flex;
  text-align: left;
  padding-left: ${theme.spacing.xxs};
  padding-right: ${theme.spacing.xxs};
  padding-top: ${theme.spacing.xxxs};
  padding-bottom: ${theme.spacing.xxs};
  border: none;
  background-color: transparent;
`

const Required = styled.div`
  color: red;
  padding-left: ${theme.spacing.xxxs};
`

export function ListDateInput(props: Props) {
  const sanitizedId = isValidUUID(props.id || '') ? props.id : undefined

  const formattedDate = props.value ? moment(props.value).format('LL h:ma') : ''

  return (
    <Container highlight={props.highlight}>
      <TitleContainer>
        {props.title} 
        {props.required && <Required>*</Required>}
      </TitleContainer>
      <TextInput id={sanitizedId} placeholder={props.placeholder} disabled={props.disabled} value={formattedDate} onChange={(e) => props.onChange && props.onChange(e.target.value)} />
    </Container>
  )
}