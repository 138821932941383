import styled from "styled-components";
import { theme } from "../theme";
import { Box } from "./Box";
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';

interface Props {
  label: string;
  onClick?: (checked: boolean) => void;
  value?: boolean;
  disabled?: boolean;
}

const Container = styled.div<{ disabled?: boolean; }>`
  background-color: rgba(255,255,255,0.04);
  font-size: ${theme.typography.textSizes.sm};
  color: white;
  display: flex;
  justify-content: left;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  margin: ${theme.spacing.xxs};
  border-radius: 12px;
  transition: all 0.2s;
  font-weight: ${theme.typography.weights.strong};
  user-select: none;
  align-items: center;
`

const Checked = styled(CheckBox)`
  font-size: 15px;
`

const UnChecked = styled(CheckBoxOutlineBlankOutlined)`
  font-size: 15px;
`

export function SidebarCheckbox(props: Props) {
  return (
    <Container 
      disabled={props.disabled} 
      onClick={() => props.onClick && props.onClick(!props.value)}
    >
      {props.value && <Checked />}
      {!props.value && <UnChecked />}

      <Box paddingLeftCode="xxs">
        {props.label}
      </Box>
    </Container>
  )
}