import { useQuery } from 'react-query';
import { RawField, fieldRepo } from '../repositories/FieldRepo';
import { Field } from '../models/Field';
import { uuid } from '../helpers/UUID';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useFields = (categoryId?: string) => {
  const { 
    isLoading, 
    data: fields,
    refetch,  
  } = useQuery(['fields',categoryId], async () => {
    if (!categoryId) {
      return [];
    }

    const rawFields = await fieldRepo.getByCategoryId(categoryId)
    const fields = rawFields.map(row => {
      const field = new Field();
      field.fromRawData(row) 
      return field
    })

    const nonDeletedFields = fields.filter(field => !field.getDeleted())

    return nonDeletedFields
  }, options)

  return {
    fields,
    refetch,
    isLoading,
  }
}