import axios from "axios";
import { config } from "../config";
import { FieldType, ReservedFieldType } from "../models/Field";
import { dataProvider } from "../realtimeDatabase";

export interface RawField { 
  id: string;
  name: string;
  categoryId: string;
  width?: number;
  required?: boolean;
  type: FieldType;
  dropdownOptions?: string[];
  showInList?: boolean;
  reservedFieldType?: ReservedFieldType;
  sortOrder: number | null;
  deleted: boolean;
}

class FieldRepo {

  public  async getById(categoryId: string, id: string) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/fields/${id}`, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawField
      return resource
    } catch(e) {
      console.log('Failed to get field by id',e)
    }
    return null
  }

  public async getByCategoryId(categoryId: string): Promise<RawField[]> {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.get(`${config.backend.baseurl}/categories/${categoryId}/fields`, { headers: { Authorization: `Bearer ${token}` }})
      const resources = response.data.resources as RawField[]
      return resources
    } catch(e) {
      console.log('Failed to get fields',e)
    }
    return []
  }

  public  async save(data: RawField) {
    try {
      const token = await dataProvider.getToken()
      const response = await axios.put(`${config.backend.baseurl}/categories/${data.categoryId}/fields/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` }})
      const resource = response.data.resource as RawField
      return resource
    } catch(e) {
      console.log('Failed to save field',e)
    }
    return null
  }

}

export const fieldRepo = new FieldRepo()
