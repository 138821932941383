import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import { Header } from "./Header";
import { Footer } from "./Footer";

const MainSection = styled.div`
  font-size: ${theme.typography.textSizes.xl};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
  flex-direction: column;
  align-items: center
`

export const Privacy = observer(function () {
  return <>

    <Header />

    <MainSection>

      <Box paddingTopCode="xxxl">
        <Box paddingBottomCode="xl">
          <Text size="xxxl" weight="medium" color="black">Privacy Policy for Record Rooster</Text>
        </Box>
        <Text size="md">
          Introduction<br/>
          <br/>
          This Privacy Policy outlines how Record Rooster ("App", "we", "us", or "our") collects, uses, and protects your information when you use our App.<br/>
          <br/>
          Information Collection<br/>
          <br/>
          Personal Information: We collect personal information that you provide, such as your name, email address, and account details.<br/>
          Usage Data: We collect data related to your use of the App, including access times and app features used.<br/>
          <br/>
          Use of Information<br/>
          <br/>
          App Functionality: Your information is used to provide, maintain, and improve the App’s features and services.<br/>
          Communication: We may use your information to communicate with you about App updates, support, and other related messages.<br/>
          <br/>
          Sharing of Information<br/>
          <br/>
          We do not sell, trade, or rent your personal information to others.<br/>
          We may share generic aggregated demographic information not linked to any personal identification information with our business partners for the purposes outlined above.<br/>
          <br/>
          Data Security<br/>
          <br/>
          We implement reasonable security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.<br/>
          However, no method of transmission over the internet or electronic storage is 100% secure.<br/>
          <br/>
          User Rights<br/>
          <br/>
          You have the right to access, update, or delete your personal information stored in the App.<br/>
          You can exercise these rights by contacting us at the provided contact details.<br/>
          <br/>
          Changes to Policy<br/>
          <br/>
          We reserve the right to update this Privacy Policy at any time.<br/>
          We will notify you of any changes by posting the new Privacy Policy on this page.<br/>
          <br/>
          Contact Us<br/>
          <br/>
          For any questions or concerns about this Privacy Policy, please contact us.
          <br/>
          By using Record Rooster, you consent to the collection and use of information in accordance with this policy.
        </Text>
      </Box>

    </MainSection>

    <Footer />

  </>
})
