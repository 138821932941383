import { Button } from '../../components/Button'
import { useParams } from 'react-router-dom';
import { useCategory } from '../../hooks/useCategory';
import { useMe } from '../../hooks/useMe';
import { useData } from '../../hooks/useData';
import { RequestedChange } from '../../models/RequestedChange';
import { uuid } from '../../helpers/UUID';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useRequestedChanges } from '../../hooks/useRequestedChanges';
import { useFields } from '../../hooks/useFields';
import { useVersions } from '../../hooks/useVersions';
import { useUsersType } from '../../hooks/useUsersType';
import { Permissions, injectCategoryId } from '../../shared/Permissions';

export const RequestChangeButton = observer(function () {
  const { categoryId, recordId } = useParams()
  const { me } = useMe()
  const { category } = useCategory(categoryId)
  const { data } = useData(categoryId, recordId)
  const { requestedChanges, refetch: refetchRequestedChanges } = useRequestedChanges(categoryId, recordId)
  const { versions } = useVersions(categoryId, recordId)
  const { fields } = useFields(categoryId)
  const isNewDocument = versions?.length === 0
  const { userType } = useUsersType(me?.getType() || '')

  if (!category || !me || !data || !recordId) {
    return <></>;
  }

  if (!category.getRequireApproval()) {
    return <></>;
  }

  if (userType?.hasPermission(injectCategoryId(Permissions.CATEGORY_SAVE_WITHOUT_APPROVAL, categoryId || ''))) {
    return <></>;
  }

  if (data.getPendingNewDocumentApproval()) {
    return <></>
  }

  return (
    <Button 
      onClick={async () => {
        if (isNewDocument) {
          data.setHasChangeRequests(true)
          data.setPendingNewDocumentApproval(true)
          data.saveEdits()
          refetchRequestedChanges()
          return 
        }
        const requestedChange = new RequestedChange()
        requestedChange.setId(uuid.generate())
        requestedChange.setDate(moment().toISOString())
        requestedChange.setUserId(me.getId())
        requestedChange.setData(data.getEdits())
        requestedChange.setRecordId(recordId)
        await requestedChange.save()
        data.clearEdits()
        data.setHasChangeRequests(true)
        await data.save()
        refetchRequestedChanges()
      }} 
      disabled={!data.hasEdits() || !data.canSave(fields || [])}
    >
      {isNewDocument ? 'Request Approval' : 'Request Change'}
    </Button>
  )
})
