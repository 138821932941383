import { Link as RRLink } from "react-router-dom";

interface Props {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export function Link(props: Props) {
  if (props.to) {
    return (<RRLink to={props.to || ''}>
      {props.children}
    </RRLink>)
  }
  return (
  <div onClick={props.onClick} style={{ cursor: 'pointer' }}>
    {props.children}
  </div>
  )
}