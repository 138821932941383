import { observer } from "mobx-react";
import styled from "styled-components";
import { theme } from "../../theme";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import { Link } from "../../components/Link";
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";

const MainSection = styled.div`
  font-size: ${theme.typography.textSizes.xl};
  font-weight: ${theme.typography.weights.strong};
  display: flex;
  text-align: center;
  background: white;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const ScreenshotContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
`

const Screenshot = styled.img`
  max-width: min(900px, 100%);
  border-radius: 15px;
  transform: scale(0.9);
  filter: brightness(70%);
  box-shadow: 0px 50px 40px -30px rgba(0,0,0,0.5), 0px 0px 70px rgba(0,0,0,0.2);
`

const Popup = styled.img`
  border-radius: 15px;
  box-shadow: 0px 50px 40px -30px rgba(0,0,0,0.5), 0px 0px 70px rgba(0,0,0,0.2);
  position: absolute;
  height: 100%;
  left: 50%;
  z-index: 99;
  transform: translateX(-50%);
`

const FeatureContainer = styled.div`
  padding: ${theme.spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
`

const CategoryPanel = styled.div`
  height: 400px;
  width: 220px;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  border-radius: 20px;
  background-size: cover;
  background-position: center center;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  box-shadow: -10px 30px 50px -20px rgba(0,0,0,0.7);
  @media (max-width: ${theme.screenSizes.tablet}px) {
    height: 300px;
    width: 130px;
  }
`

const CategoryText = styled.div`
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  padding-bottom: ${theme.spacing.md};
  padding-top: ${theme.spacing.xxxl};
  font-weight: ${theme.typography.weights.medium};
  line-height: 0px;
  width: 100%;
`

const UserTypeContainer = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  max-width: 200px;
  justify-content: top;
`

const UserTypeBubble = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
  margin-bottom: 20px;
  @media (max-width: ${theme.screenSizes.tablet}px) {
    height: 80px;
    width: 80px;
  }
`

const Tial = styled.div`
  border-radius: 10px;
  background-color: ${theme.palette.accent};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  cursor: pointer;
  line-height: 0px;
  border-radius: 50px;
`

const Versions = styled.img`
  width: 100%;
  max-width: min(700px, 100%);
`

export const Promo = observer(function () {
  const navigate = useNavigate()

  async function login() {
    const user = await firebase.auth().currentUser
    if (user) {
      navigate('/dashboard')
    } else {
      navigate('/signin')
    }
  }

  return <>
    <Header />
      
    <MainSection>

      <Box paddingTopCode="xxl">
        <Text size="xxxl" weight="strong" color="black" align="center">
          Record Management
        </Text>
      </Box>

      <Box paddingTopCode="xxxs" >
        <Text size="lg" color={theme.palette.accent}>
          <div style={{ maxWidth: 600, textAlign: 'center' }}>
            Control your unique data effortlessly
          </div>
        </Text>
      </Box>

      <Box paddingBottomCode="xxxl" paddingTopCode="xxxl">
        <ScreenshotContainer>
          <Popup src={require('../../assets/popup.jpg')} alt='popup' />
          <Screenshot src={require('../../assets/main.jpg')} alt='screenshot' />
        </ScreenshotContainer>
      </Box>

      <Box paddingTopCode="xxxl">
        <Text size="xxxl" weight="medium" color="black">
          Flexibility
        </Text>
      </Box>

      <Box paddingTopCode="md" paddingLeftCode="md" paddingRightCode="md">
        <Text size="lg" color="#81818B">
          <div style={{ maxWidth: 600, textAlign: 'center' }}>
            Create categories and fields within those categories that match your buisness needs. From free form to dropdowns. 
          </div>
        </Text>
      </Box>

      
      <Box paddingBottomCode="xxxl" paddingTopCode="xxxl">

        <CategoryPanel style={{ backgroundImage: `url(${require('../../assets/manual.jpg')})`, zIndex: 1, transform: 'scale(0.6) rotate(0deg) translateX(250px)',  }}>
          <CategoryText>
            <Text size="lg" color="white" align="center" weight="strong">
              Manuals
            </Text>
            <br/> <br/>
            <Text size="md" color="fadedWhite" align="center" >
              Product<br/>
              Expiry<br/>
              Company
            </Text>
          </CategoryText>
        </CategoryPanel>

        <CategoryPanel style={{ backgroundImage: `url(${require('../../assets/zoo.jpg')})`, zIndex: 2, transform: 'scale(0.8) rotate(0deg) translateX(65px)', }}>
          <CategoryText>
            <Text size="lg" color="white" align="center" weight="strong">
              Animals
            </Text>
            <br/> <br/>
            <Text size="md" color="fadedWhite" align="center" >
              Name<br/>
              Birth year<br/>
              Species
            </Text>
          </CategoryText>
        </CategoryPanel>

        <CategoryPanel style={{ backgroundImage: `url(${require('../../assets/draw.jpg')})`, zIndex: 4, transform: 'rotate(0deg) translateX(0px)', }}>
          <CategoryText>
            <Text size="lg" color="white" align="center" weight="strong">
              Blueprints
            </Text>
            <br/> <br/>
            <Text size="md" color="fadedWhite" align="center" >
              Location<br/>
              Type<br/>
              Author
            </Text>
          </CategoryText>
        </CategoryPanel>

        <CategoryPanel style={{ backgroundImage: `url(${require('../../assets/patient.jpg')})`, zIndex: 3, transform: 'scale(0.8) rotate(-0deg) translateX(-65px)',}}>
          <CategoryText>
            <Text size="lg" color="white" align="center" weight="strong">
            Patients
            </Text>
            <br/> <br/>
            <Text size="sm" color="fadedWhite" align="center" >
              First Name<br/>
              Last Name<br/>
              Address
            </Text>
          </CategoryText>
        </CategoryPanel>

        <CategoryPanel style={{ backgroundImage: `url(${require('../../assets/other.jpg')})`, zIndex: 1, transform: 'scale(0.6) rotate(0deg) translateX(-250px)' }}>
          <CategoryText>
            <Text size="lg" color="white" align="center" weight="strong">
              Other
            </Text>
            <br/> <br/>
            <Text size="md" color="fadedWhite" align="center" >
              Custom Field 1<br/>
              Custom Field 2<br/>
              Custom Field 3
            </Text>
          </CategoryText>
        </CategoryPanel>

      </Box>

      <Box paddingTopCode="xxxl">
        <Text size="xxxl" weight="medium" color="black">
          Control
        </Text>
      </Box>

      <Box paddingTopCode="md" paddingLeftCode="md" paddingRightCode="md">
        <Text size="lg" color="#81818B">
          <div style={{ maxWidth: 600, textAlign: 'center' }}>
            Create categories and fields within those categories that match your buisness needs. From free form to dropdowns. 
          </div>
        </Text>
      </Box>

      
      <Box paddingBottomCode="xxxl" paddingTopCode="xxxl" >
        
        <UserTypeContainer>
          <UserTypeBubble style={{ backgroundImage: `url(${require('../../assets/admin.jpg')})`, }} />
          <Text size="xl" weight="strong" align="center">
              Admin 
          </Text>
          <Text size="md" weight="medium" align="center">
              Save without approval
              <br/>
              Approval Access
          </Text>
        </UserTypeContainer>

        <UserTypeContainer>
          <UserTypeBubble style={{ backgroundImage: `url(${require('../../assets/keeper.jpg')})`, }} />
          <Text size="xl" weight="strong" align="center">
              Zoo Keeper 
          </Text>
          <Text size="md" weight="medium" align="center">
              Save without approval
              <br/>
              View Animals
          </Text>
        </UserTypeContainer>

        <UserTypeContainer>
          <UserTypeBubble style={{ backgroundImage: `url(${require('../../assets/engineer.jpg')})`, }} />
          <Text size="xl" weight="strong"  align="center">
              Engineer 
          </Text>
          <Text size="md" weight="medium" align="center">
              Requires approval to edit
              <br/>
              View Drawings
          </Text>
        </UserTypeContainer>

      </Box>

      <Box paddingTopCode="xxxl">
        <Text size="xxxl" weight="medium" color="black">
          Versions
        </Text>
      </Box>

      <Box paddingTopCode="md" paddingBottomCode="xxxl" paddingLeftCode="md" paddingRightCode="md">
        <Text size="lg" color="#81818B">
          <div style={{ maxWidth: 600, textAlign: 'center' }}>
            Each time a document is updated, the previous version is stored for future reference. 
          </div>
        </Text>
      </Box>

      <Versions src={require('../../assets/versions.png')} />

      <Box paddingTopCode="xxxl">
        <Text size="xxxl" weight="medium" color="black">
          Pricing
        </Text>
      </Box>

      <Box paddingTopCode="md" paddingLeftCode="md" paddingRightCode="md">
        <Text size="lg" color="#81818B">
          <div style={{ maxWidth: 600, textAlign: 'center' }}>
            Affordable pricing that scales with your buisness. 
          </div>
        </Text>
      </Box>
      
      <Box paddingBottomCode="xxl" paddingTopCode="xl">

        <FeatureContainer>
          <Box paddingTopCode="sm" direction="vertical" alignVertical="center">
            <Text size="xxxl" weight="strong" color="black">
              $5
              <Text size="sm" weight="medium">/ Month</Text>
            </Text>
            <Text size="md" weight="medium" color="black">Per User</Text>
          </Box>
        </FeatureContainer>

        <FeatureContainer>
          <Box paddingTopCode="sm" direction="vertical" alignVertical="center">
            <Text size="xxxl" weight="strong" color="black">
              2¢ 
              <Text size="sm" weight="medium">/ Month</Text>
            </Text>
            <Text size="md" weight="medium" color="black">Per Record</Text>
          </Box>
        </FeatureContainer>

      </Box>

      <Link onClick={() => login()}>
        <Box paddingBottomCode="lg">
          <Tial>
            <Text size="md" color="white" weight="strong">Start Free Trial</Text>
          </Tial>
        </Box>
      </Link>

    </MainSection>

    <Footer />
  </>
})
