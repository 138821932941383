import { useQuery } from 'react-query';
import { User } from '../models/User';
import { userRepo } from '../repositories/UserRepo';

const options = { 
  refetchOnMount: false,
  refetchOnWindowFocus: false
}

export const useUsers = () => {
  const { 
    isLoading, 
    data: users,
    refetch,  
  } = useQuery(['users'], async () => {

    const userData = await userRepo.getAll()
    const users = userData.map(row => {
      const user = new User();
      user.fromRawData(row) 
      return user
    })

    const formattedUsers = users.map(user => {
      if (user.getDeleted()) {
        user.setName(`${user.getName()} (deleted)`)
      }
      return user
    })

    return formattedUsers
  }, options)

  return {
    users,
    refetch,
    isLoading,
  }
}