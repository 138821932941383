import styled from "styled-components";
import { KeyboardArrowDown as MaterialSortIconDown } from '@mui/icons-material';
import { KeyboardArrowUp as MaterialSortIconUp } from '@mui/icons-material';

interface Props {
  direction: 'asc' | 'desc';
}

const SortIconDown = styled(MaterialSortIconDown)`
  color: black;
`

const SortIconUp = styled(MaterialSortIconUp)`
  color: black;
`

const Container = styled.div`
  width: 18px;
  height: 18px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function SortArrow(props: Props) {
  return (
    <Container>
      {props.direction === 'asc' && <SortIconDown fontSize={'small'} />}
      {props.direction === 'desc' && <SortIconUp fontSize={'small'} />}      
    </Container>
  )
}